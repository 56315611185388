// export const FairPoolAddress = '0xb82ef38a8Ba64e1Beb0cc4e5155116a32B65a431'
// export const FairPoolFactoryAddress = '0x4a72a4D222408E0BdB0483C75964E3cAE4405d5C'

export const FairPoolFactory_Proxy = '0x3E88fee6dCBf6f7DC97AAf3AA722e237641E7E0b'
export const FairPoolFactory_Implementation = '0xa632AC8f68C7A2dcF6a01A54F07684DC11F6E26d'

export const FairPoolReaderAddress = '0xDd0d35D523637Bf108b9156Cb012331803cc20F0'

export enum ChainId {
  LineaTestnet = 59140,
  Linea = 59144,
}

export const TOKEN_LIST = {
  [ChainId.LineaTestnet]: {
    USDC: '',
    USDT: '0xEcF4F13B8a0E2b9a82172d4456D2ade209830470',
  },
  [ChainId.Linea]: {
    USDC: '',
    USDT: '',
  },
}
