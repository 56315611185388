import { useEffect, useState } from 'react'

export const Options = ({
  options,
  onChange,
  defaultValue,
}: {
  options: { label: any; value: string | boolean }[]
  onChange: (value: string | boolean) => void
  defaultValue?: string
}) => {
  const [selectedKey, setSelectedKey] = useState(options[0].value)
  const handleOnSelect = (selectedKey: string | boolean) => () => {
    setSelectedKey(selectedKey)
    onChange(selectedKey)
  }

  useEffect(() => {
    const setDefaultValue = () => {
      onChange(defaultValue || options[0].value)
    }
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {options.map((option, index) => (
        <div key={index} className="flex items-center hover:cursor-pointer" onClick={handleOnSelect(option.value)}>
          <input type="checkbox" checked={selectedKey === option.value} className="checkbox checkbox-secondary" />
          <span className="ml-2 text-dark-base-content-06">{option.label}</span>
        </div>
      ))}
    </>
  )
}
