import { createContext, ReactNode, useContext, useMemo, useState } from 'react'
const MISSING_PROVIDER = Symbol()

type PoolType = {
  [x: string]: any
}

enum Step {
  Step1 = 1,
  Step2,
  Step3,
  Step4,
}

const FairLaunchContext = createContext<
  | {
      currentStep: Step
      setCurrentStep: React.Dispatch<React.SetStateAction<Step>>
      steps: { title: string; content: string }[]
    }
  | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

const steps = [
  { title: 'Verify Token', content: 'Enter the token address and verify' },
  {
    title: 'DeFi Fairlaunch Info',
    content:
      'Enter the launchpad information that you want to raise , that should be enter all details about your presale',
  },
  { title: 'Add Additional Info', content: 'Let people know who you are' },
  { title: 'Finish', content: 'Review your information' },
]

export function FairLaunchProvider({ children }: { children: ReactNode }) {
  const [currentStep, setCurrentStep] = useState<Step>(1)

  const value = useMemo(() => ({ currentStep, setCurrentStep, steps }), [currentStep])

  return <FairLaunchContext.Provider value={value}>{children}</FairLaunchContext.Provider>
}

export function useFairLaunchContext() {
  const createFairLaunch = useContext(FairLaunchContext)
  if (createFairLaunch === MISSING_PROVIDER) {
    throw new Error('CreateFairLaunchContext hooks must be wrapped in a <CreateFairLaunchProvider>')
  }
  return createFairLaunch
}
