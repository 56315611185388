import { PageWrapper } from 'components/FairLaunch/styled'
import { FormProvider, useForm } from 'react-hook-form'

import { FairLaunchProvider, useFairLaunchContext } from '../useFairLaunchContext'
import FormItemAdditionalInfo from './FormItemAdditionalInfo'
import FormItemFairLaunchInfo from './FormItemFairLaunchInfo'
import FormItemFinish from './FormItemFinish'
import FormItemVerifyToken from './FormItemVerifyToken'
import Steps from './Steps'

const CreateFairLaunch = () => {
  const { steps, currentStep } = useFairLaunchContext()

  return (
    <>
      <PageWrapper>
        <Steps steps={steps} currentStep={currentStep} />
        {currentStep === 1 ? <FormItemVerifyToken /> : null}
        {currentStep === 2 ? <FormItemFairLaunchInfo /> : null}
        {currentStep === 3 ? <FormItemAdditionalInfo /> : null}
        {currentStep === 4 ? <FormItemFinish /> : null}
      </PageWrapper>
    </>
  )
}

const ProviderWrapper = () => {
  const methods = useForm()
  return (
    <FormProvider {...methods}>
      <FairLaunchProvider>
        <CreateFairLaunch />
      </FairLaunchProvider>
    </FormProvider>
  )
}

export default ProviderWrapper
