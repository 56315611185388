import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { ChainId } from '@uniswap/sdk-core'

import store from '../../state/index'

const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  [ChainId.ARBITRUM_ONE]: 'https://thegraph.com/hosted-service/subgraph/ianlapham/uniswap-arbitrum-one',
  [ChainId.OPTIMISM]: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis',
  [ChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
  [ChainId.CELO]: 'https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo',
  [ChainId.BNB]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-bsc',
  [ChainId.AVALANCHE]: 'https://api.thegraph.com/subgraphs/name/lynnshaoyu/uniswap-v3-avax',
  // [ChainId.LINEA_GOERLI]: 'https://graph.lineax.pro/subgraphs/name/lineax/uniswap-v3',
  [ChainId.ZKATANA]: 'https://graph.l2x.pro/subgraphs/name/zkatana/uniswap-v3',
  [ChainId.ZKEVM]: 'https://graph.l2x.pro/subgraphs/name/zkevm/unitest',
  [ChainId.ZKYOTO]: 'https://graph.l2x.pro/subgraphs/name/L2X/zkyoto',
}

const httpLink = new HttpLink({ uri: CHAIN_SUBGRAPH_URL[ChainId.ZKEVM] })

// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const chainId = store.getState().application.chainId

  operation.setContext(() => ({
    uri: chainId && CHAIN_SUBGRAPH_URL[chainId] ? CHAIN_SUBGRAPH_URL[chainId] : CHAIN_SUBGRAPH_URL[ChainId.ZKEVM],
  }))

  return forward(operation)
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})

export const chainToApolloClient: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [ChainId.MAINNET]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.MAINNET],
  }),
  [ChainId.ARBITRUM_ONE]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.ARBITRUM_ONE],
  }),
  [ChainId.OPTIMISM]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.OPTIMISM],
  }),
  [ChainId.POLYGON]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.POLYGON],
  }),
  [ChainId.CELO]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.CELO],
  }),
  [ChainId.BNB]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.BNB],
  }),
  [ChainId.AVALANCHE]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.AVALANCHE],
  }),
  [ChainId.ZKATANA]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.ZKATANA],
  }),
  [ChainId.ZKEVM]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.ZKEVM],
  }),
  [ChainId.ZKYOTO]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.ZKYOTO],
  }),
}

const chainToBlockClientUrl = {
  [ChainId.ZKEVM]: 'https://graph.l2x.pro/subgraphs/name/zkevm/blocks',
  [ChainId.ZKYOTO]: 'https://graph.l2x.pro/subgraphs/name/zkyoto/blocks',
  [ChainId.ZKATANA]: 'https://graph.l2x.pro/subgraphs/name/zkatana/blocks',
}

export const zkyotoBlockClient = new ApolloClient({
  // uri: 'https://graph.l2x.pro/subgraphs/name/zkatana/blocks',
  uri: 'https://graph.l2x.pro/subgraphs/name/zkyoto/blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const zkEVMBlockClient = new ApolloClient({
  // uri: 'https://graph.l2x.pro/subgraphs/name/zkatana/blocks',
  uri: 'https://graph.l2x.pro/subgraphs/name/zkevm/blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const zkatanaBlockClient = new ApolloClient({
  uri: 'https://graph.l2x.pro/subgraphs/name/zkatana/blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
