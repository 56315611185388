import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useAccountDrawer } from 'components/AccountDrawer'
import Web3Status from 'components/Web3Status'
import { chainIdToBackendName } from 'graphql/data/util'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import { useIsExplorePage } from 'hooks/useIsExplorePage'
import { useIsFairLaunchPage } from 'hooks/useIsFairLaunchPage'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPointsPage, useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { LineaXTextIcon } from 'nft/components/icons'
import { useProfilePageState } from 'nft/hooks'
import { ReactNode, useCallback } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useIsMobile } from '../../nft/hooks/useIsMobile'
import { useIsNavSearchInputVisible } from '../../nft/hooks/useIsNavSearchInputVisible'
import { isDevelopmentEnv, isTestEnv } from '../../utils/env'
import Blur from './Blur'
import { ChainConnecter } from './ChainConnecter'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 18px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()
  const { chainId: connectedChainId } = useWeb3React()
  const chainName = chainIdToBackendName(connectedChainId)

  const isPoolActive = useIsPoolsPage()
  const isNftPage = useIsNftPage()
  const isPointsPage = useIsPointsPage()
  const isExplorePage = useIsExplorePage()
  const isFairLaunchPage = useIsFairLaunchPage()
  const shouldDisableNFTRoutes = useDisableNFTRoutes()

  return (
    <>
      <MenuItem href="/swap" isActive={pathname.startsWith('/swap')}>
        <Trans>
          <span style={{ fontSize: '20px' }}>Swap</span>
        </Trans>
      </MenuItem>
      {/* <MenuItem href={`/tokens/${chainName.toLowerCase()}`} isActive={pathname.startsWith('/tokens')}>
        <Trans>Tokens</Trans>
      </MenuItem> */}
      {!shouldDisableNFTRoutes && (
        <MenuItem dataTestId="nft-nav" href="/nfts" isActive={isNftPage}>
          <Trans>NFTs</Trans>
        </MenuItem>
      )}
      <Box width="full">
        <MenuItem href="/pools" dataTestId="pool-nav-link" isActive={isPoolActive}>
          <Trans>
            <span style={{ fontSize: '20px' }}>Pools</span>
          </Trans>
        </MenuItem>
      </Box>
      {/* <Box marginY="4"><MenuDropdown /></Box> */}
      {/* <Box width="full">
        <a
          className={styles.menuItem}
          // href="https://testnet-analytics.l2x.pro"
          href="https://analytics.l2x.pro"
          rel="noreferrer"
        >
          <Trans>Analytics</Trans>
        </a>
      </Box> */}
      <Box width="full">
        <MenuItem href="/explore" dataTestId="pool-nav-link" isActive={isExplorePage}>
          <Trans>
            <span style={{ fontSize: '20px' }}>Explore</span>
          </Trans>
        </MenuItem>
      </Box>
      {/* <div className="tooltip tooltip-bottom" data-tip="coming soon">
        <Box style={{ color: '#9B9B9B', padding: '8px 14px', fontSize: '18px', cursor: 'not-allowed' }}>
          <Trans>Analytics</Trans>
        </Box>
      </div> */}
      {isDevelopmentEnv() && (
        <Box width="full">
          <a className={styles.menuItem} href="https://testnet-faucet.l2x.pro" target="_blank" rel="noreferrer">
            <Trans>Faucet</Trans>
          </a>
        </Box>
      )}
      {/* <MenuItem href="/fairlaunch" dataTestId="pool-nav-link" isActive={isFairLaunchPage}>
        <div className="w-[120px]">
          <Trans>IDO Launchpad</Trans>
        </div>
      </MenuItem> */}
      {/* {isDevelopmentEnv() && (
        <Box width="full">
          <MenuItem href="/points" isActive={isPointsPage}>
            <Trans>
              <span style={{ fontSize: '20px' }}>Points</span>
            </Trans>
          </MenuItem>
        </Box>
      )} */}
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  const isNavSearchInputVisible = useIsNavSearchInputVisible()

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const isMobile = useIsMobile()

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box
          display="flex"
          height="full"
          flexWrap="nowrap"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Box className="flex flex-1">
            <Box className={styles.logoContainer}>
              <LineaXTextIcon
                width="110"
                height="40"
                transform="scale(0.9)"
                data-testid="uniswap-logo"
                className={styles.logo}
                onClick={handleUniIconClick}
              />
              {isDevelopmentEnv() && <Box className={styles.testnetText}>[Testnet]</Box>}
            </Box>
          </Box>
          <Box display={{ sm: 'none', md: 'none', lg: 'flex' }} className="flex-1 justify-center">
            <div className="flex items-center ">
              <PageTabs />
            </div>
            {/* <SearchBar /> */}
          </Box>
          <Box className={styles.rightSideContainer2} style={{ flex: '1', justifyContent: 'flex-end' }}>
            <Row gap="12">
              <Box>
                <ChainConnecter />
              </Box>
              <Web3Status />
              {(isDevelopmentEnv() || isTestEnv()) && isMobile && (
                <Box className="flex items-center pl-2 pb-1">
                  <div className="drawer drawer-end">
                    <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
                    <div className="drawer-content">
                      {/* Page content here */}
                      <label htmlFor="my-drawer-4" className="drawer-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M3.9751 5.97461H19.9751" stroke="#A6ADBA" strokeWidth="1.5" />
                          <path d="M3.9751 11.9746H19.9751" stroke="#A6ADBA" strokeWidth="1.5" />
                          <path d="M3.9751 17.9746H19.9751" stroke="#A6ADBA" strokeWidth="1.5" />
                        </svg>
                      </label>
                    </div>
                    <div className="drawer-side z-50">
                      <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
                      <ul className="menu p-4 w-60 min-h-full text-base-content bg-[#191e24]">
                        {/* Sidebar content here */}
                        <div onClick={() => document.getElementById('my-drawer-4')?.click()}>
                          <PageTabs />
                        </div>
                      </ul>
                    </div>
                  </div>
                </Box>
              )}
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar
