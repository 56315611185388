import { darken } from 'polished'
import { useState } from 'react'
import styled, { keyframes } from 'styled-components'

const Wrapper = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  align-items: center;
  /* background: ${({ isActive, theme }) => (isActive ? theme.accent2 : 'transparent')}; */
  border: ${({ theme, isActive }) => (isActive ? `1px solid ${theme.accent1}` : `1px solid ${theme.neutral3}`)};
  /* border-radius: 20px; */
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 4px;
  width: fit-content;
`

const turnOnToggle = keyframes`
  from {
    margin-left: 0em;
    margin-right: 1.6em;
  }
  to {
    margin-left: 1.6em;
    margin-right: 0em;
  }
`

const turnOffToggle = keyframes`
  from {
    margin-left: 1.6em;
    margin-right: 0em;
  }
  to {
    margin-left: 0em;
    margin-right: 1.6em;
  }
`

const ToggleElementHoverStyle = (hasBgColor: boolean, theme: any, isActive?: boolean) =>
  hasBgColor
    ? {
        opacity: '0.8',
      }
    : {
        background: isActive ? darken(0.05, theme.accent1) : darken(0.05, theme.surface3),
        color: isActive ? theme.white : theme.neutral3,
      }

const ToggleElement = styled.span<{ isActive?: boolean; bgColor?: string; isInitialToggleLoad?: boolean }>`
  animation: 0.1s
    ${({ isActive, isInitialToggleLoad }) => (isInitialToggleLoad ? 'none' : isActive ? turnOnToggle : turnOffToggle)}
    ease-in;
  background: ${({ theme, bgColor, isActive }) =>
    isActive ? bgColor ?? theme.accent1 : bgColor ? theme.surface3 : theme.neutral3};
  /* border-radius: 50%; */
  border-radius: 2px;
  height: 20px;
  :hover {
    ${({ bgColor, theme, isActive }) => ToggleElementHoverStyle(!!bgColor, theme, isActive)}
  }
  margin-left: ${({ isActive }) => isActive && '1.6em'};
  margin-right: ${({ isActive }) => !isActive && '1.6em'};
  width: 20px;
`

interface ToggleProps {
  id?: string
  bgColor?: string
  isActive: boolean
  toggle: () => void
}

export default function Toggle({ id, bgColor, isActive, toggle }: ToggleProps) {
  const [isInitialToggleLoad, setIsInitialToggleLoad] = useState(true)

  const switchToggle = () => {
    toggle()
    if (isInitialToggleLoad) setIsInitialToggleLoad(false)
  }

  return (
    <Wrapper id={id} data-testid={id} role="option" aria-selected={isActive} isActive={isActive} onClick={switchToggle}>
      <ToggleElement isActive={isActive} bgColor={bgColor} isInitialToggleLoad={isInitialToggleLoad} />
    </Wrapper>
  )
}
