import { ApolloProvider } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import { asSupportedChain, DEFAULT_CHAINID } from 'constants/chains'
import { PropsWithChildren } from 'react'

import { chainToApolloClient } from './apollo'

export function GraphProvider({ children }: PropsWithChildren) {
  const { chainId } = useWeb3React()
  const client = chainToApolloClient[asSupportedChain(chainId) ?? DEFAULT_CHAINID]

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
