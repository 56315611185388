import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { useWeb3React } from '@web3-react/core'
import { DarkCard } from 'components/Card'
import { formatUnits } from 'ethers/lib/utils'
import useInterval from 'lib/hooks/useInterval'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFairlaunchActionHandlers } from 'state/fairlaunch/hooks'

import { findTokenNameByAddress, getCountdown } from '../utils'
import { PoolStatus } from './PoolStatus'

const SEC_IN_MIN = 60

export const ProjectCard = ({ pool }: { pool: { [x: string]: any } }) => {
  const { chainId } = useWeb3React()
  const [countDown, setCountDown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
  const { tokenInfo, poolInfo, payment, saleInfo, liquidity, logoUrl, isLive, isNotStart } = pool
  const { name } = tokenInfo
  const { token: paymentToken } = payment
  const paymentTokenName = chainId ? findTokenNameByAddress({ address: paymentToken, chainId }) : ''

  const {
    starttime: bigNumStartTime,
    endtime: bigNumEndTime,
    hardCap: bigNumHardCap,
    softCap: bigNumSoftCap,
    tokensForSale: bigNumTokensForSale,
  } = poolInfo

  const { totalClaimed: bigNumTotalClaimed, totalRaised: bigNumTotalRaised } = saleInfo
  const {
    tokensForLiquidity: bigNumTokensForLiquidity,
    unlockTime: bigNumUnlockTime,
    lockTime: bigNumLockTime,
  } = liquidity
  const lockupTime = bigNumUnlockTime.sub(bigNumLockTime).div(BigNumber.from(SEC_IN_MIN)).toNumber().toFixed()
  // const progressRate = bigNumTotalRaised.div(bigNumSoftCap).mul(100000).toNumber()
  const progressRate = Number(bigNumTotalRaised.div(bigNumSoftCap).toString()) * 100

  const startTime = Number(bigNumStartTime?.toNumber()) * 1000
  const endTime = Number(bigNumEndTime?.toNumber()) * 1000

  const navigate = useNavigate()
  const handleGetCountdown = useCallback(() => {
    const { days, hours, minutes, seconds } = getCountdown(isNotStart ? startTime : endTime)
    setCountDown({ days, hours, minutes, seconds })
  }, [endTime])
  const { onSelectPool } = useFairlaunchActionHandlers()

  useInterval(handleGetCountdown, 1000)

  return (
    <DarkCard className="!w-[365px]">
      <div className="flex w-full justify-between mb-4">
        <div>
          {/* <div className="w-[80px] h-[80px] bg-slate-500 rounded-full"></div> */}
          <div className="avatar">
            <div className="w-[80px] rounded-full bg-slate-500">
              <img src={logoUrl} />
            </div>
          </div>
          <div className="mt-2">{name}</div>
          {/* <div className="text-[12px] text-dark-base-content-04">1 USDT = 1,000,000 MTT</div> */}
        </div>
        <div>
          <PoolStatus startTime={startTime} endTime={endTime} />
          {/* <div className="my-2 flex justify-end gap-2">
            <div className="bg-dark-base-content-04 inline-block px-2 py-[2px] text-white">KYC</div>
            <div className="bg-dark-base-content-04 inline-block px-2 py-[2px] text-white">KYC</div>
          </div> */}
        </div>
      </div>
      <div className="text-[12px]">{bigNumHardCap.eq(MaxUint256) ? 'Soft' : 'Soft/Hard'}</div>
      <div className="text-secondary text-[16px] font-medium">
        {formatUnits(bigNumSoftCap.toString())} {paymentTokenName}
        {bigNumHardCap.eq(MaxUint256) ? null : (
          <span>- {bigNumHardCap.eq(MaxUint256) ? 'MAX' : `${bigNumHardCap.toString()} ${paymentTokenName}`}</span>
        )}
      </div>
      <div className="mt-4">Progress({progressRate}%)</div>
      <div>
        <progress className="progress progress-secondary  bg-dark-base-100" value={progressRate} max="100"></progress>
        <div className="flex justify-between text-[12px] text-dark-base-content-06">
          <div>
            {formatUnits(bigNumTotalRaised.toString())} {paymentTokenName}
          </div>
          <div>
            {formatUnits(bigNumSoftCap.toString())} {paymentTokenName}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <div>Liquidity %</div>
        <div>{bigNumTokensForLiquidity.mul(1e2).div(bigNumTokensForSale).toString()}%</div>
      </div>
      <div className="flex justify-between mt-2">
        <div>Lockup Time</div>
        <div>{lockupTime} minutes</div>
      </div>
      <div className="w-full h-[1px] bg-dark-base/[0.1] my-4"></div>

      <div className="flex justify-between">
        <div>
          <div className="text-[12px] text-dark-base-content-06">
            {isLive ? 'Sale Ends In' : isNotStart ? 'Sale Start in' : '--'}
          </div>
          <span className="countdown">
            <span style={{ '--value': countDown.days } as React.CSSProperties}></span>:
            <span style={{ '--value': countDown.hours } as React.CSSProperties}></span>:
            <span style={{ '--value': countDown.minutes } as React.CSSProperties}></span>:
            <span style={{ '--value': countDown.seconds } as React.CSSProperties}></span>
          </span>
        </div>
        <button
          className="btn btn-gradient"
          onClick={() => {
            onSelectPool(pool)
            navigate(`/fairlaunch/1`)
          }}
        >
          VIEW
        </button>
      </div>
    </DarkCard>
  )
}
