import './common.css'

import bgLine from 'assets/svg/bg-line.svg'
import useFactoriesQuery from 'graphql/thegraph/FactoriesQuery'
import { formatUSDPriceWithCommas } from 'nft/utils'

export const HeroStatistics = () => {
  const { data: factoriesData, isLoading } = useFactoriesQuery()
  const totalFeesUSD = (factoriesData as any)?.factories?.[0]?.totalFeesUSD ?? 0
  const totalTVL = (factoriesData as any)?.factories?.[0]?.totalValueLockedUSD ?? 0
  const totalVolumeUSD = (factoriesData as any)?.factories?.[0]?.totalVolumeUSD ?? 0
  const data = [
    {
      title: 'Total Volume',
      value: totalVolumeUSD,
    },
    {
      title: 'Total Fees',
      value: totalFeesUSD,
    },
    {
      title: 'TVL',
      value: totalTVL,
    },
  ]

  return (
    <div className="flex flex-col items-center justify-center w-screen gap-16 relative mt-72 md:mt-12 z-[999]">
      <img className="w-[1154px] h-[443px] absolute top-[52px] z-[0]" src={bgLine} alt="line" />

      <div className="flex flex-col md:flex-row gap-6 z-0 text-white w-full max-w-7xl justify-center px-4">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col w-full after:bg-gradient-primary relative after:absolute after:z-[-1] after:top-2 after:right-[-0.5rem]  after:w-full after:h-full p-3 bg-black border border-dark-base-content-06"
          >
            {!Number(item.value) ? (
              <span className="text-[36px] text-[#6b6f78]">Coming soon</span>
            ) : (
              <span className="l-text-48 !text-[24px] md:!text-[48px]">
                {isLoading ? '...' : formatUSDPriceWithCommas(item.value)}
              </span>
            )}
            <span className="l-text-sm uppercase">{item.title}</span>
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center justify-center relative w-screen min-h-[328px] md:min-h-[428px] z-0">
        {/* <img
          className="w-[628px] h-[628px] absolute z-[10] top-0 left-1/2 transform -translate-x-1/2"
          src={bgStar}
          alt="star"
        /> */}
        <p className="text-center text-white l-text-3xl-semibold !text-[28px] md:!text-[30px] z-10 max-w-[936px] relative px-2">
          As a concentrated liquidity Automated Market Maker (AMM), L2X redefines the way you trade, offering high
          capital efficiency with smooth trading
        </p>
      </div>
    </div>
  )
}
