import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns'

import { ChainId, TOKEN_LIST } from './constants'

export function formatLargeNumber(number: number) {
  console.log({ number })
  if (number >= 1e9) {
    // 十亿或更大
    return (number / 1e9).toFixed(1) + 'B'
  } else if (number >= 1e6) {
    // 百万或更大
    return (number / 1e6).toFixed(1) + 'M'
  } else if (number >= 1e3) {
    // 千或更大
    return (number / 1e3).toFixed(1) + 'K'
  } else {
    return number.toString()
  }
}

function findKeyByValue<T>(obj: { [key: string]: T }, value: T): string | undefined {
  return Object.keys(obj).find((key) => obj[key] === value)
}

export function findTokenNameByAddress({ address, chainId }: { address: string; chainId: ChainId }) {
  return findKeyByValue(TOKEN_LIST[chainId], address)
}

export function getCountdown(targetDateTimeInSec: number) {
  const now = new Date()

  // 计算当前时间与目标日期之间的差异
  const days = differenceInDays(targetDateTimeInSec, now)
  const hours = differenceInHours(targetDateTimeInSec, now) % 24
  const minutes = differenceInMinutes(targetDateTimeInSec, now) % 60
  const seconds = differenceInSeconds(targetDateTimeInSec, now) % 60

  return { days, hours, minutes, seconds }
}
