import ReactECharts from 'echarts-for-react'

export const MetricsChart = ({ presale, liquidity }: { presale?: number; liquidity?: number }) => {
  return (
    <ReactECharts
      theme="dark"
      option={{
        title: {
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        backgroundColor: '#242933',
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: liquidity, name: 'Liquidity' },
              { value: presale, name: 'Presale' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
              },
            },
          },
        ],
      }}
    />
  )
}
