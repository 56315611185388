const Steps = ({
  steps,
  currentStep,
}: {
  steps: {
    title: string
    content: string
  }[]
  currentStep: number
}) => {
  return (
    <div className="flex gap-4 my-4 justify-between">
      {steps.map((step, index) => {
        return (
          <>
            <div key={index} className="flex flex-1 max-w-[230px]">
              <div
                className={`p-4 w-[36px] h-[36px] rounded-full ${
                  currentStep === index + 1
                    ? 'bg-secondary text-white'
                    : currentStep > index + 1
                    ? 'text-secondary border-secondary border'
                    : 'border text-dark-base-content-04 border-dark-base-content-04'
                } flex justify-center items-center `}
              >
                {index + 1}
              </div>
              <div className="ml-2">
                <div>{step.title}</div>
                <div className="text-dark-base-content-06 text-[12px]">{step.content}</div>
              </div>
            </div>
            <div className="w-[80px] h-[1px] bg-dark-base-content-04 last:hidden mt-6"></div>
          </>
        )
      })}
    </div>
  )
}

export default Steps
