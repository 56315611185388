import { useNavigate } from 'react-router-dom'
import { useFairlaunchActionHandlers } from 'state/fairlaunch/hooks'
import { FairlaunchPoolState } from 'state/fairlaunch/reducer'

export const SaleCell = ({ pool }: { pool: FairlaunchPoolState }) => {
  const { tokenInfo, poolInfo, saleInfo, logoUrl } = pool
  const { name, symbol } = tokenInfo
  const { tokensForSale: bigNumTokensForSale } = poolInfo
  const { totalClaimed: bigNumTotalClaimed } = saleInfo
  const { onSelectPool } = useFairlaunchActionHandlers()
  const navigate = useNavigate()

  return (
    <div
      className="flex flex-col bg-dark-base-100 p-3  mb-4"
      onClick={() => {
        onSelectPool(pool)
        navigate(`/fairlaunch/detail`)
      }}
    >
      <div className="mb-4 pb-4 border-b border-dark-base-content/[0.1] flex justify-between text-dark-base-content-06 text-[12px] ">
        <div className="flex-1">Token</div>
        <div className="flex-1">Token Offered</div>
        <div className="flex-1">Price</div>
        <div className="flex-1">Participants</div>
        <div className="flex-1">Total committed</div>
        <div className="flex-1 text-right pr-4">Status</div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-1 items-center">
          <div className="avatar">
            <div className="w-[48px] rounded-full">
              <img className="bg-slate-500" src={logoUrl} />
            </div>
          </div>
          <p className="pl-2">{name}</p>
        </div>
        <div className="flex items-center flex-1">
          {bigNumTokensForSale.toString()} {symbol}
        </div>
        <div className="flex items-center flex-1">--</div>
        <div className="flex items-center flex-1">--</div>
        <div className="flex items-center flex-1">{bigNumTotalClaimed.toString()}</div>
        <div className="flex items-center flex-1">
          <div className="w-full text-right pr-4">End</div>
        </div>
      </div>
    </div>
  )
}
