import { t } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { MouseoverTooltip } from 'components/Tooltip'
import { getChainInfo } from 'constants/chainInfo'
import { DEFAULT_CHAINID } from 'constants/chains'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { useIsMobile, useIsTablet } from 'nft/hooks'
import { useCallback, useRef, useState } from 'react'

import * as styles from './ChainSelector.css'

interface ChainSelectorProps {
  leftAlign?: boolean
}

export const ChainConnecter = ({ leftAlign }: ChainSelectorProps) => {
  const { chainId } = useWeb3React()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const info = getChainInfo(chainId)

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<ChainId | undefined>(undefined)

  const onSelectChain = useCallback(
    async (targetChainId: ChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen]
  )

  if (!chainId) {
    return null
  }

  const isSupported = !!info
  const isActive = chainId === DEFAULT_CHAINID
  const defaultChain = getChainInfo(DEFAULT_CHAINID)

  return (
    <div>
      <Box position="relative" ref={ref}>
        <MouseoverTooltip text={t`Your wallet's current network is unsupported.`} disabled={isSupported}>
          <Row
            data-testid="chain-selector"
            as="button"
            gap="8"
            className={styles.ChainChooser}
            style={{ borderColor: isActive ? '#A6ADBA40' : '#FF7176' }}
            background={isOpen ? 'accent2' : 'none'}
            onClick={() => onSelectChain(DEFAULT_CHAINID)}
          >
            {isActive ? (
              <>
                <Box className={styles.Indicator} />
                <img
                  src={defaultChain.logoUrl}
                  alt={defaultChain.label}
                  className={styles.Image}
                  data-testid="chain-selector-logo"
                />
                <Box display={isTablet ? 'none' : 'block'} color="neutral1">
                  {defaultChain.label}
                </Box>
              </>
            ) : (
              <Box display="block" color="neutral1">
                {isTablet || isMobile ? (
                  <Box className="flex items-center" style={{ fontSize: '16px', color: '#e5e7eb' }}>
                    <Box className={styles.Indicator} style={{ background: '#FF7176' }} />
                    <Box>{defaultChain.label}</Box>
                  </Box>
                ) : (
                  <>
                    <Box className="flex items-center" style={{ fontSize: '12px', color: '#FF7176' }}>
                      <Box className={styles.Indicator} style={{ background: '#FF7176' }} />
                      <Box>Network chainid: {chainId}</Box>
                    </Box>
                    <Box style={{ fontSize: '14px', color: '#e5e7eb' }}>Switch to {defaultChain.label}</Box>
                  </>
                )}
              </Box>
            )}
          </Row>
        </MouseoverTooltip>
      </Box>
    </div>
  )
}
