import ArrowSvg from 'assets/svg/arrow-down.svg'
import CheckedSvg from 'assets/svg/checked.svg'
import LinkSvg from 'assets/svg/link.svg'
import Rank1Svg from 'assets/svg/rank1.svg'
import Rank2Svg from 'assets/svg/rank2.svg'
import Rank3Svg from 'assets/svg/rank3.svg'
import RewardSvg from 'assets/svg/reward.svg'
import U235Svg from 'assets/svg/u235.svg'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyleTr = styled.tr`
  border-bottom-color: #8b8b8b1a;
`

const PointsPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="flex flex-col max-w-[1200px] gap-6 mt-12">
      <div className="flex flex-row bg-dark-base-100 h-[88px] py-[24px] px-[16px] gap-[24px] items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <img alt="token" src={U235Svg} />
          <div className="flex flex-col">
            <div className="flex flex-row items-baseline gap-1">
              <span className="text-2xl-semibold">U235</span>
              <span className="text-2xl-semibold">points</span>
              <Link to="">
                <img alt="link" src={LinkSvg} />
              </Link>
            </div>
            <span className="text-xs">Last Updated: Mar 04 2024 16:35</span>
          </div>
        </div>
        <div className="flex flex-row gap-1 mt-[32px]">
          <span className="text-xl-medium">Total L2x Points</span>
          <span className="text-xl-medium text-secondary">7.356B</span>
          <span className="text-xl-medium ml-2">Users</span>
          <span className="text-xl-medium text-secondary">235,54K</span>
        </div>
      </div>

      <div className="flex flex-row w-full gap-6">
        <div className="flex flex-col flex-1 gap-6">
          <span className="text-2xl-semibold text-white">Leaderboard</span>
          <div className="bg-dark-base-100">
            <div className="overflow-x-auto">
              <table className="table table-divider">
                {/* head */}
                <thead>
                  <StyleTr>
                    <th className="text-sm text-dark-base-content-06">Rank</th>
                    <th className="text-sm text-dark-base-content-06">Name</th>
                    <th className="text-sm text-dark-base-content-06">Invited</th>
                    <th className="text-sm text-dark-base-content-06">
                      <div className="flex flex-row gap-1 justify-end">
                        Participants
                        <img alt="arrow" src={ArrowSvg} />
                      </div>
                    </th>
                  </StyleTr>
                </thead>
                <tbody>
                  {/* row */}
                  {[
                    {
                      address: '0x86...6a39',
                      points: 114454,
                    },
                    {
                      address: '0x86...6a39',
                      points: 114454,
                    },
                    {
                      address: '0x86...6a39',
                      points: 114454,
                    },
                    {
                      address: '0x86...6a39',
                      points: 114454,
                    },
                    {
                      address: '0x86...6a39',
                      points: 114454,
                    },
                    {
                      address: '0x86...6a39',
                      points: 114454,
                    },
                  ].map(({ address, points }, index) => (
                    <StyleTr key={index} className="h-[64px]">
                      <th>
                        <div className="flex items-center flex-row gap-2">
                          <div className="w-[12px]">
                            {index < 3 && <img src={index === 0 ? Rank1Svg : index === 1 ? Rank2Svg : Rank3Svg} />}
                          </div>
                          {index + 1}
                        </div>
                      </th>
                      <td>{address}</td>
                      <td>
                        <div className="flex flex-row gap-1 items-center">
                          {address} <img alt="link" src={LinkSvg} />
                        </div>
                      </td>
                      <td className="text-right">{points}</td>
                    </StyleTr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <span className="text-2xl-semibold text-white mt-3">Recent Joins</span>
          <div className="flex flex-row flex-wrap p-3 bg-dark-base-100 gap-3">
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
            <span className="l-text-base text-dark-base-content">0x86...8a39</span>
          </div>
        </div>
        <div className="flex flex-col w-[463px] gap-6">
          <span className="text-2xl-semibold text-white">Your L2X Points</span>

          <div className="flex flex-col bg-dark-base-100 p-3 gap-6">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center gap-2">
                <img alt="token" src={U235Svg} className="w-9 h-9" />
                <span className="text-xl-medium">U235 Points</span>
              </div>

              <span className="text-xl-medium text-secondary">1000.00</span>
            </div>
            <div className="flex flex-row justify-end gap-2">
              <img alt="reward" src={RewardSvg} />
              <span>Rewards May 2024</span>
            </div>
          </div>

          <div className="collapse collapse-arrow bg-dark-base-100">
            <div tabIndex={0} className="collapse collapse-arrow">
              <input type="checkbox" />
              <div className="collapse-title text-xl font-medium">
                <div>How to Earn</div>
              </div>
              <div className="collapse-content">
                <div className="w-full flex flex-col">
                  {[
                    {
                      title: 'Provide liquidity and lock LP tokens',
                      isChecked: true,
                      operation: 'Lock',
                      points: '20x point / Day',
                      hasIcon: true,
                    },
                    {
                      title: 'Hold USDe YT or LP on Pendle',
                      isChecked: true,
                      operation: 'Zep in',
                      points: '20x point / Day',
                      hasIcon: true,
                    },
                    {
                      title: 'Lock USDe',
                      isChecked: true,
                      operation: 'Lock',
                      points: '20x point / Day',
                      hasIcon: true,
                    },
                    {
                      title: 'Buy and hold USDe',
                      isChecked: true,
                      operation: 'Lock',
                      points: '20x point / Day',
                      hasIcon: false,
                    },
                    {
                      title: 'Stake and hold sUSDe',
                      isChecked: true,
                      operation: 'Buy',
                      points: '20x point / Day',
                      hasIcon: false,
                    },
                    {
                      title: 'Invite people to L2X',
                      isChecked: true,
                      operation: '',
                      points: '20x point / Day',
                      hasIcon: false,
                    },
                  ].map((item) => (
                    <Fragment key={item.title}>
                      <div className="w-full h-[1px] bg-divider-gray" />
                      <div className="flex flex-row items-center py-2 justify-between h-[60px]">
                        <div className="flex flex-row gap-2">
                          <span className="w-[132px] text-sm-medium">{item.title}</span>
                          {item.isChecked && <img src={CheckedSvg} alt="checked" />}
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          {item.hasIcon && <img src={U235Svg} alt="" className="w-5 h-5" />}
                          <span className="text-sm-medium text-secondary">{item.operation}</span>
                          <span className="text-sm-medium">{item.points}</span>
                        </div>
                      </div>
                    </Fragment>
                  ))}

                  <div className="w-full h-[1px] bg-divider-gray" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PointsPage
