import { Content } from './components/Content'
import { Footer } from './components/Footer'
import { HeroMain } from './components/HeroMain'
import { HeroStatistics } from './components/HeroStatistics'

const Page = () => {
  return (
    <div className="flex flex-col w-screen bg-dark-base-300 overflow-hidden">
      {/* <Nav /> */}
      <HeroMain />
      <HeroStatistics />
      <Content />
      {/* <Footer /> */}
      <Footer />
    </div>
  )
}

export default Page
