import { useWeb3React } from '@web3-react/core'
import { Erc20Interface } from 'abis/types/Erc20'
import { add, getUnixTime } from 'date-fns'
import { BigNumber, constants } from 'ethers/lib'
import { Interface } from 'ethers/lib/utils'
import { useContract } from 'hooks/useContract'
import { useMultipleContractSingleData, useSingleCallResult } from 'lib/hooks/multicall'
import { useState } from 'react'
import { useIsTransactionConfirmed } from 'state/transactions/hooks'
import { TransactionInfo, TransactionType } from 'state/transactions/types'
import { UserRejectedRequestError } from 'utils/errors'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

import { FairlaunchPoolState } from '../../state/fairlaunch/reducer'
import FAIR_POOL_ABI from './abis/FairPoolABI.json'
import FAIR_POOL_FACTORY_ABI from './abis/FairPoolFactory.json'
import FAIR_POOL_READER_ABI from './abis/FairPoolReaderABI.json'
import TOKEN_INFO_ABI from './abis/TokenInfoABI.json'
import { FairPoolFactory_Proxy, FairPoolReaderAddress } from './constants'

const GAS_LIMIT = 50 * 1e5

export function useFairPoolContribute(poolAddress: string) {
  const fairPoolContract = useContract(poolAddress, FAIR_POOL_ABI)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const contribute = async (amount: number) => {
    try {
      setIsLoading(true)
      setIsSuccess(false)
      const response = await fairPoolContract?.contribute(BigNumber.from(String(amount * 1e18)), {
        gasLimit: GAS_LIMIT,
      })
      setIsLoading(false)
      setIsSuccess(true)
      return {
        response,
        info: {
          type: TransactionType.CONTRIBUTE_TO_FAIR_LAUNCH,
        } as TransactionInfo,
      }
    } catch (e: unknown) {
      setIsLoading(false)
      if (didUserReject(e)) {
        throw new UserRejectedRequestError(`contribute failed: User rejected`)
      }
      throw new Error(`contribute failed: ${e instanceof Error ? e.message : e}`)
    }
  }

  return {
    isLoading,
    isSuccess,
    contribute,
  }
}

export function useFairPoolSetEndTime(poolAddress: string) {
  const fairPoolContract = useContract(poolAddress, FAIR_POOL_ABI, true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const setEndTime = async (endTimeInSec: number) => {
    try {
      setIsLoading(true)
      setIsSuccess(false)
      const response = await fairPoolContract?.setEndtime(BigNumber.from(endTimeInSec), {
        gasLimit: GAS_LIMIT,
      })
      setIsLoading(false)
      setIsSuccess(true)
      return {
        response,
        info: {
          type: TransactionType.CANCEL_FAIR_LAUNCH,
        } as TransactionInfo,
      }
    } catch (e: unknown) {
      setIsLoading(false)
      if (didUserReject(e)) {
        throw new UserRejectedRequestError(`cancel failed: User rejected`)
      }
      throw new Error(`cancel failed: ${e instanceof Error ? e.message : e}`)
    }
  }

  return {
    isLoading,
    isSuccess,
    setEndTime,
  }
}

export function useFairPoolCancel(poolAddress: string) {
  const fairPoolContract = useContract(poolAddress, FAIR_POOL_ABI, true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const cancelPool = async () => {
    try {
      setIsLoading(true)
      setIsSuccess(false)
      const response = await fairPoolContract?.cancel({
        gasLimit: GAS_LIMIT,
      })
      setIsLoading(false)
      setIsSuccess(true)
      return {
        response,
        info: {
          type: TransactionType.CANCEL_FAIR_LAUNCH,
        } as TransactionInfo,
      }
    } catch (e: unknown) {
      setIsLoading(false)
      if (didUserReject(e)) {
        throw new UserRejectedRequestError(`cancel failed: User rejected`)
      }
      throw new Error(`cancel failed: ${e instanceof Error ? e.message : e}`)
    }
  }

  return {
    isLoading,
    isSuccess,
    cancelPool,
  }
}

export function useFairyPoolReader() {
  const { account } = useWeb3React()
  const fairPoolReaderContract = useContract(FairPoolReaderAddress, FAIR_POOL_READER_ABI, true)
  const { result, loading } = useSingleCallResult(fairPoolReaderContract, 'poolList')

  const poolList = result?.[0]

  const endedList: FairlaunchPoolState[] = []
  const onGoingList: FairlaunchPoolState[] = []
  const notStartList: FairlaunchPoolState[] = []

  const myEndedList: FairlaunchPoolState[] = []
  const myOnGoingList: FairlaunchPoolState[] = []
  const myNotStartList: FairlaunchPoolState[] = []

  console.log({ poolList })

  const expandPool = (pool: FairlaunchPoolState) => {
    const [website, logoUrl, github, facebook, twitter, telegram, instagram, discord, reddit, youtube, description] =
      pool?.poolDetail?.split(',') || []

    const bigNumEndTime = pool?.poolInfo?.endtime
    const bigNumStartTime = pool?.poolInfo?.starttime
    const endTime = Number(bigNumEndTime?.toNumber()) * 1000
    const startTime = Number(bigNumStartTime?.toNumber()) * 1000
    const now = new Date().valueOf()
    const isEnd = endTime < now
    const isLive = now > startTime && now < endTime
    const isNotStart = startTime > now

    return {
      ...pool,
      website,
      logoUrl,
      github,
      facebook,
      twitter,
      telegram,
      instagram,
      discord,
      reddit,
      youtube,
      description,
      isEnd,
      isNotStart,
      isLive,
    }
  }

  const sortPool = (pool: FairlaunchPoolState) => {
    const { isEnd, isNotStart } = pool
    const creator = pool?.poolInfo?.creator

    if (isEnd) {
      endedList.push(pool)
      if (creator === account) {
        myEndedList.push(pool)
      }
    } else if (isNotStart) {
      notStartList.push(pool)
      if (creator === account) {
        myNotStartList.push(pool)
      }
    } else {
      onGoingList.push(pool)
      if (creator === account) {
        myOnGoingList.push(pool)
      }
    }
  }

  if (poolList?.length > 0) {
    poolList.forEach((pool: any) => {
      const expandedPool = expandPool(pool)
      sortPool(expandedPool)
    })
  }

  return {
    getPools: () => fairPoolReaderContract?.getPools(),
    poolList: {
      loading,
      all: {
        endedList,
        onGoingList,
        notStartList,
      },
      my: {
        endedList: myEndedList,
        onGoingList: myOnGoingList,
        notStartList: myNotStartList,
      },
    },
  }
}

export function useFairLaunchCreatePool({
  totalSellingAmount,
  currency,
  softCap,
  website,
  logoUrl,
  isWhitelist,
  maxContribution,
  isMaxBuyLimit,
  startTime,
  endTime,
  tokenAddress,
  liquidityRate,
  lockTime,
  github,
  facebook,
  twitter,
  telegram,
  instagram,
  discord,
  reddit,
  youtube,
  description,
}: FairlaunchPoolState) {
  const { account } = useWeb3React()
  const [isLoading, setIsLoading] = useState(false)
  const [txHash, setTxHash] = useState('')
  const fairPoolFactoryContract = useContract(FairPoolFactory_Proxy, FAIR_POOL_FACTORY_ABI, true)
  const { token, name, symbol, decimals, totalSupply: totalsupply } = useTokenInfo(tokenAddress)
  const PADDING_NUMBERS = 10 ** decimals
  const tokenInfo = [token, name, symbol, decimals, totalsupply]
  const payment = [
    currency?.address, // currency address
    0, // fee
  ]
  const poolInfo = [
    account, // creator
    0, //state 0=InProgress 1=Completed 2=Cancelled
    BigNumber.from(String(totalSellingAmount)), // tokensForSale
    BigNumber.from(String(Number(softCap) * PADDING_NUMBERS)), // softCap // TODO should be payment token decimals
    constants.MaxUint256, // hardCap
    (new Date(startTime).valueOf() / 1000).toFixed(), // timestamp in seconds
    (new Date(endTime).valueOf() / 1000).toFixed(), // timestamp in seconds
    maxContribution || 0,
    !!isMaxBuyLimit, // isMaxBuyLimit
    isWhitelist,
  ]
  const endTimeDate = new Date(endTime)
  const liquidityAmount = ((totalSellingAmount * liquidityRate) / 100).toFixed()
  const liquidity = [
    BigNumber.from(String(liquidityAmount)), // tokensForLiquidity
    getUnixTime(endTimeDate), // unlockTime
    getUnixTime(add(endTimeDate, { minutes: lockTime })), // unlockTime
  ]
  const poolDetail = `${website},${logoUrl},${github},${facebook},${twitter},${telegram},${instagram},${discord},${reddit},${youtube},${description}`
  const isSuccess = useIsTransactionConfirmed(txHash)
  const createPool = async () => {
    setIsLoading(true)
    try {
      const response = await fairPoolFactoryContract?.createPool(tokenInfo, payment, poolInfo, liquidity, poolDetail, {
        gasLimit: 50 * 1e5, // TODO set gas limit
      })
      setTxHash(response?.hash || '')
      setIsLoading(false)
      return {
        response,
        info: {
          type: TransactionType.CREATE_FAIR_LAUNCH_POOL,
        },
      }
    } catch (e: unknown) {
      setIsLoading(false)
      if (didUserReject(e)) {
        throw new UserRejectedRequestError(`${symbol} create fairlaunch pool failed: User rejected`)
      }
      throw new Error(`${symbol} create fairlaunch pool failed: ${e instanceof Error ? e.message : e}`)
    }
  }

  return {
    isLoading,
    isSuccess,
    fairPoolFactoryAddress: FairPoolFactory_Proxy,
    createPool,
  }
}

export function useTokenInfo(tokenAddress: string) {
  const contract = useContract(tokenAddress, TOKEN_INFO_ABI)

  const { result: resName } = useSingleCallResult(contract, 'name')
  const { result: resDecimals } = useSingleCallResult(contract, 'decimals')
  const { result: resTotalSupply } = useSingleCallResult(contract, 'totalSupply')
  const { result: resSymbols } = useSingleCallResult(contract, 'symbol')
  // const { result: resOwner } = useSingleCallResult(contract, 'owner')

  const getDecimals = useMultipleContractSingleData(
    [tokenAddress],
    new Interface(TOKEN_INFO_ABI) as Erc20Interface,
    'decimals'
  )

  return {
    token: tokenAddress,
    name: resName?.[0],
    decimals: resDecimals?.[0],
    symbol: resSymbols?.[0],
    totalSupply: resTotalSupply?.[0],
    // owner: resOwner?.[0],
    error: getDecimals?.[0].error,
  }
}
