import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'

import { selectPool } from './actions'
import { FairlaunchPoolState } from './reducer'

export function useFairlaunchActionHandlers() {
  const dispatch = useAppDispatch()

  const onSelectPool = useCallback(
    (pool: FairlaunchPoolState) => {
      dispatch(selectPool(pool))
    },
    [dispatch]
  )

  return {
    onSelectPool,
  }
}
