import { ApolloError, useQuery } from '@apollo/client'
import { PollingInterval } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'

import { FeeTierDistributionQuery } from './__generated__/types-and-hooks'
import { apolloClient } from './apollo'

const query = gql`
  query pools {
    pools(where: { id_in: [] }, orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
      id
      feeTier
      liquidity
      sqrtPrice
      tick
      token0 {
        id
        symbol
        name
        decimals
        derivedETH
        __typename
      }
      token1 {
        id
        symbol
        name
        decimals
        derivedETH
        __typename
      }
      token0Price
      token1Price
      volumeUSD
      volumeToken0
      volumeToken1
      txCount
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedUSD
      __typename
    }
    bundles(where: { id: "1" }) {
      ethPriceUSD
      __typename
    }
  }
`

export default function useETHPriceQuery(): {
  error?: ApolloError
  isLoading: boolean
  data: FeeTierDistributionQuery
} {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(query, {
    variables: {},
    pollInterval: PollingInterval.Normal,
    client: apolloClient,
  })

  return useMemo(
    () => ({
      error,
      isLoading,
      data,
    }),
    [data, error, isLoading]
  )
}
