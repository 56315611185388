import { ApolloError, useQuery } from '@apollo/client'
import { PollingInterval } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'

import { FeeTierDistributionQuery } from './__generated__/types-and-hooks'
import { apolloClient } from './apollo'

const query = gql`
  query uniswapFactories {
    factories(first: 1, subgraphError: allow) {
      txCount
      totalVolumeUSD
      totalFeesUSD
      totalValueLockedUSD
      __typename
    }
  }
`

export default function useFactoriesQuery(): {
  error?: ApolloError
  isLoading: boolean
  data: FeeTierDistributionQuery
} {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(query, {
    variables: {},
    pollInterval: PollingInterval.Normal,
    client: apolloClient,
  })

  return useMemo(
    () => ({
      error,
      isLoading,
      data,
    }),
    [data, error, isLoading]
  )
}
