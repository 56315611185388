import tokenLogoLookup from 'constants/tokenLogoLookup'
import { isCelo, isZKEVM, nativeOnChain } from 'constants/tokens'
import { checkWarning, WARNING_LEVEL } from 'constants/tokenSafety'
import { chainIdToNetworkName, getNativeLogoURI } from 'lib/hooks/useCurrencyLogoURIs'
import uriToHttp from 'lib/utils/uriToHttp'
import { useCallback, useMemo, useReducer } from 'react'
import { isAddress } from 'utils'

import celoLogo from '../assets/svg/celo_logo.svg'

const BAD_SRCS: { [tokenAddress: string]: true } = {}

// Converts uri's into fetchable urls
function parseLogoSources(uris: string[]) {
  const urls: string[] = []
  uris.forEach((uri) => urls.push(...uriToHttp(uri)))
  return urls
}

// Parses uri's, favors non-coingecko images, and improves coingecko logo quality
function prioritizeLogoSources(uris: string[]) {
  const parsedUris = uris.map((uri) => uriToHttp(uri)).flat(1)
  const preferredUris: string[] = []

  // Consolidate duplicate coingecko urls into one fallback source
  let coingeckoUrl: string | undefined = undefined

  parsedUris.forEach((uri) => {
    if (uri.startsWith('https://assets.coingecko')) {
      if (!coingeckoUrl) {
        coingeckoUrl = uri.replace(/small|thumb/g, 'large')
      }
    } else {
      preferredUris.push(uri)
    }
  })
  // Places coingecko urls in the back of the source array
  return coingeckoUrl ? [...preferredUris, coingeckoUrl] : preferredUris
}

function getInitialUrl(
  address?: string | null,
  chainId?: number | null,
  isNative?: boolean,
  backupImg?: string | null
) {
  if (chainId && isNative) return getNativeLogoURI(chainId)

  const networkName = chainId ? chainIdToNetworkName(chainId) : 'ethereum'
  const checksummedAddress = isAddress(address)

  if (chainId && isCelo(chainId) && address === nativeOnChain(chainId).wrapped.address) {
    return celoLogo
  }

  if (chainId && isZKEVM(chainId) && address === nativeOnChain(chainId).wrapped.address) {
    return 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
  }

  // USDC
  if (
    chainId &&
    isZKEVM(chainId) &&
    address?.toLocaleLowerCase() === '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035'.toLocaleLowerCase()
  ) {
    return 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'
  }

  // USDT
  if (
    chainId &&
    isZKEVM(chainId) &&
    address?.toLocaleLowerCase() === '0x1E4a5963aBFD975d8c9021ce480b42188849D41d'.toLocaleLowerCase()
  ) {
    return 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png'
  }

  // MATIC
  if (
    chainId &&
    isZKEVM(chainId) &&
    address?.toLocaleLowerCase() === '0xa2036f0538221a77A3937F1379699f44945018d0'.toLocaleLowerCase()
  ) {
    return 'https://assets.coingecko.com/coins/images/4713/standard/polygon.png'
  }

  // DOT
  if (
    chainId &&
    isZKEVM(chainId) &&
    address?.toLocaleLowerCase() === '0x7Cb5d4D178d93D59ea0592abF139459957898a59'.toLocaleLowerCase()
  ) {
    return 'https://assets.coingecko.com/coins/images/12171/standard/polkadot.png'
  }

  // ASTR
  if (
    chainId &&
    isZKEVM(chainId) &&
    address?.toLocaleLowerCase() === '0xdf41220C7e322bFEF933D85D01821ad277f90172'.toLocaleLowerCase()
  ) {
    return 'https://assets.coingecko.com/coins/images/22617/standard/astr.png'
  }

  // WBTC
  if (
    chainId &&
    isZKEVM(chainId) &&
    address?.toLocaleLowerCase() === '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1'.toLocaleLowerCase()
  ) {
    return 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png'
  }

  // wstETH
  if (
    chainId &&
    isZKEVM(chainId) &&
    address?.toLocaleLowerCase() === '0x5D8cfF95D7A57c0BF50B30b43c7CC0D52825D4a9'.toLocaleLowerCase()
  ) {
    return 'https://assets.coingecko.com/coins/images/32890/standard/WSTETH.png'
  }

  if (checksummedAddress) {
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${networkName}/assets/${checksummedAddress}/logo.png`
  } else {
    return backupImg ?? undefined
  }
}

export default function useAssetLogoSource(
  address?: string | null,
  chainId?: number | null,
  isNative?: boolean,
  primaryImg?: string | null
): [string | undefined, () => void] {
  const hideLogo = Boolean(address && checkWarning(address, chainId)?.level === WARNING_LEVEL.BLOCKED)
  const [srcIndex, incrementSrcIndex] = useReducer((n: number) => n + 1, 0)

  const current = useMemo(() => {
    if (hideLogo) return undefined

    if (primaryImg && !BAD_SRCS[primaryImg] && !isNative) return primaryImg

    const initialUrl = getInitialUrl(address, chainId, isNative)
    if (initialUrl && !BAD_SRCS[initialUrl]) return initialUrl

    const uris = tokenLogoLookup.getIcons(address, chainId) ?? []
    const fallbackSrcs = prioritizeLogoSources(parseLogoSources(uris))
    return fallbackSrcs.find((src) => !BAD_SRCS[src])
    // eslint-disable-next-line react-hooks/exhaustive-deps -- rerun when src index changes, denoting a bad src was marked
  }, [address, chainId, hideLogo, isNative, primaryImg, srcIndex])

  const nextSrc = useCallback(() => {
    if (current) BAD_SRCS[current] = true
    incrementSrcIndex()
  }, [current])

  return [current, nextSrc]
}
