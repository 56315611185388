export const PoolStatus = ({ startTime, endTime }: { startTime: number; endTime: number }) => {
  const now = new Date().valueOf()
  const isLive = now > startTime && now < endTime
  const isNotStart = startTime > now
  const isEnd = endTime < now

  return (
    <>
      {isLive ? (
        <div className="before:mr-2  before:content-[''] before:flex-block before:items-center before:rounded-full  before:w-1 before:h-1 before:bg-success border border-success px-2 py-1 text-[12px] text-success">
          Sale Live
        </div>
      ) : null}

      {isEnd ? (
        <div className="border border-dark-base-content-04 px-2 py-1 text-[12px] text-dark-base-content-04">
          Pool Ended
        </div>
      ) : null}

      {isNotStart ? (
        <div className="border border-dark-base-content-04 px-2 py-1 text-[12px] text-dark-base-content-04">
          Upcoming
        </div>
      ) : null}
    </>
  )
}
