import './common.css'

export const Footer = () => {
  return (
    <div className="py-12 md:px-6 px-2 bg-dark-base-300 flex justify-center md:justify-start">
      <ul className="flex gap-6 px-1 l-text-base text-white">
        <li>
          <a
            className="text-text-primary l-text-base hover:!text-[#A6ADBA]"
            href="https://twitter.com/L2X_pro"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
        </li>
        <li>
          <a
            className="text-text-primary l-text-base hover:!text-[#A6ADBA]"
            href="https://discord.gg/haggWxGv96"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
        </li>
        <li>
          <a
            className="text-text-primary l-text-base hover:!text-[#A6ADBA]"
            href="https://t.me/l2x_pro"
            target="_blank"
            rel="noreferrer"
          >
            Telegram
          </a>
        </li>
        <li>
          <a
            className="text-text-primary l-text-base hover:!text-[#A6ADBA]"
            href="https://l2x-pro.gitbook.io/l2x-pro/"
            target="_blank"
            rel="noreferrer"
          >
            Doc
          </a>
        </li>
      </ul>
    </div>
  )
}
