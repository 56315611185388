import './common.css'

import iconStar from 'assets/svg/icon-star.svg'
import { ReactComponent as LineaXSVG } from 'assets/svg/L2X-PRO.svg'
import { ReactComponent as MobileLineaXSVG } from 'assets/svg/L2X-PRO-mobile.svg'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useIsMobile } from 'nft/hooks'
import { useEffect, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'

import { ReactComponent as StarSVG } from '../svgs/normal-star.svg'
import { ReactComponent as ShinyStarSVG } from '../svgs/shiny-star.svg'
import { ReactComponent as StarBigSVG } from '../svgs/star-big.svg'

gsap.registerPlugin(ScrollTrigger)
const StyledNormalStar = styled(StarSVG)`
  width: 1688px;
  height: 47px;
  position: absolute;
  left: -808px;
  top: 90px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    top: 140px;
  }
`

const StyledStatBig = styled(StarBigSVG)`
  position: absolute;
  width: 598px;
  height: 598px;
  right: -140px;
  top: -50px;
  z-index: -1;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    top: -50px;
    right: -230px;
  }
`

const StyledShinyStar = styled(ShinyStarSVG)`
  width: 1688px;
  height: 47px;
  position: absolute;
  left: -808px;
  top: 90px;
  animation: opacityChange 8s infinite; /* 动画名称，动画时长，动画次数 */
  z-index: -1;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    top: 140px;
  }

  @keyframes opacityChange {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.5);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
`

const StyledLineaXSVG = styled(LineaXSVG)`
  height: 140px;
  max-width: 1200px;
  width: 100%;
`

const StyledMobileLineaXText = styled(MobileLineaXSVG)`
  height: 140px;
  max-width: 1200px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 70px;
`

export const HeroMain = () => {
  const gyroImageRef = useRef<HTMLImageElement | null>(null)
  const isMobile = useIsMobile()

  useEffect(() => {
    const gyroImage = gyroImageRef.current

    if (!gyroImage) return

    let timeoutId: NodeJS.Timeout

    const applyAnimation = (direction: 'left' | 'right') => {
      gyroImage.style.transition = 'transform 5s linear'
      const rotationAngle = direction === 'left' ? '-15deg' : '15deg'
      gyroImage.style.transform = `rotate(${rotationAngle})`
    }

    const toggleAnimation = () => {
      gyroImage.style.transition = 'none' // Remove the transition to hold the position
      // Pause for 10 seconds before starting the next animation
      timeoutId = setTimeout(() => {
        const currentRotation = gyroImage.style.transform || ''
        if (currentRotation.includes('-15')) {
          applyAnimation('right')
        } else {
          applyAnimation('left')
        }
      }, 1000) // 10 seconds
    }

    gyroImage.addEventListener('transitionend', toggleAnimation)

    // Start the animation
    applyAnimation('right')

    return () => {
      if (gyroImage) {
        gyroImage.removeEventListener('transitionend', toggleAnimation)
      }
      clearTimeout(timeoutId)
    }
  }, [])

  const starRef = useRef<any>()

  useLayoutEffect(() => {
    // Refs allow you to access DOM nodes
    // then we can animate them like so...
    gsap.fromTo(
      starRef.current,
      {
        scale: isMobile ? 0.3 : 0.8,
        y: isMobile ? 140 : 0,
        x: 0,
        autoAlpha: 8,
      },
      {
        scale: 2.6,
        y: 450,
        x: -100,
        duration: 2,
        paused: true,
        autoAlpha: 1,
        scrollTrigger: {
          trigger: starRef.current,
          start: isMobile ? 'center center' : 'center 42%',
          end: isMobile ? 'center -10%' : 'top -60%',
          scrub: true,
          toggleActions: 'play none reverse reset',
        },
      }
    )
  })

  return (
    <div className="hero h-[60vh] md:h-[95vh] relative">
      {/* <img
        ref={gyroImageRef}
        id="gyroImage"
        className="w-[1000px] md:w-[1200px] sm:w-[60vw] absolute"
        src={bgDiamon}
        alt="animation"
      /> */}
      <div className="w-full max-w-7xl text-white text-center sm:text-left px-4 z-50 relative">
        {/* <h1 className="l-text-title">LineaX</h1> */}
        <p className="l-text-lg text-left	 !text-[26px] md:!text-[45px] w-[256px] md:w-[356px] !leading-[50px] mb-12 mt-12 md:mb-8 md:mt-0">
          The <span className="font-bold">trading</span> and <span className="font-bold">liquidity hub</span> on{' '}
          <span className="font-bold">Astar zkEVM</span>
        </p>
        <div className="relative">
          {isMobile ? (
            <div className="relative">
              <StyledNormalStar />
              <StyledMobileLineaXText />
              <StyledShinyStar />
            </div>
          ) : (
            <div>
              <StyledNormalStar />
              <StyledLineaXSVG />
              <StyledShinyStar />
            </div>
          )}
        </div>

        <div>
          <StyledStatBig ref={starRef} />
        </div>
      </div>

      <div className="absolute bottom-0 w-full max-w-7xl hidden md:block text-white">
        <img src={iconStar} alt="star" className="absolute right-0 bottom-0" />
      </div>
    </div>
  )
}
