import './common.css'

import { BigNumber } from '@ethersproject/bignumber'
import { Token } from '@uniswap/sdk-core'
import bgRectangle from 'assets/svg/bg-rectangle.svg'
import bgWire from 'assets/svg/bg-wire.svg'
import curveSvg from 'assets/svg/curve.svg'
import iconETH from 'assets/svg/ethereum_square_logo.svg'
import iconArrow from 'assets/svg/icon-arrow.svg'
import iconCommunityEmpowered from 'assets/svg/icon-community-empowered.svg'
import iconLiquidityManagement from 'assets/svg/icon-liquidity-management.svg'
import iconUserExperience from 'assets/svg/icon-user-experience.svg'
import RangeBadge from 'components/Badge/RangeBadge'
import { nativeOnChain } from 'constants/tokens'
import { useDerivedPositionInfo } from 'hooks/useDerivedPositionInfo'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import JSBI from 'jsbi'
import { Bound } from 'state/mint/v3/actions'
import { useV3DerivedMintInfo } from 'state/mint/v3/hooks'
import { formatTickPrice } from 'utils/formatTickPrice'

export const Content = () => {
  const tokenA = {
    imgUrl:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
    symbol: 'DAI',
    name: 'Dai Stablecoin',
    address: '0xf4fA92d67D4BE549efd3b2251f022971Dd2Aa56F',
    chainId: 3776,
    decimals: 18,
    isNative: false,
  }
  const tokenB = {
    imgUrl: iconETH,
    symbol: 'ETH',
    name: 'ETH',
    address: '',
    decimals: 18,
    isNative: true,
  }
  const nativeCurrencyToken = nativeOnChain(3776)
  const DAIToken = new Token(tokenA.chainId, tokenA.address, tokenA.decimals, tokenA.name, 'Dai Stablecoin')
  const { position: existingPositionDetails } = useV3PositionFromTokenId(BigNumber.from(14))
  const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails)
  const info = useV3DerivedMintInfo(nativeCurrencyToken, DAIToken, 500, nativeCurrencyToken, existingPosition)
  const tokenAamount = existingPosition?.amount0.toSignificant(4)
  const tokenBamount = existingPosition?.amount1.toSignificant(4)
  const currentPrice = info?.price?.toFixed()
  const outOfRange = info?.outOfRange
  const removed = existingPosition?.liquidity && JSBI.equal(existingPosition?.liquidity, JSBI.BigInt(0))
  const priceLower = existingPosition?.token0PriceLower
  const priceUpper = existingPosition?.token0PriceUpper

  const ticksAtLimit = info.ticksAtLimit

  const handleAddLiquidityClick = () => {
    // TODO update href
    return
    // 创建一个 <a> 元素，并设置其 href 属性来触发跳转
    // const link = document.createElement('a')
    // // link.href = isProductionEnv() ? 'https://testnet.l2x.pro/#/pools' : '#/pool'
    // link.href = isProductionEnv() ? '' : '#/pool' // TODO update href like above
    // // 模拟点击 <a> 元素
    // link.click()
  }

  return (
    <>
      <div className="flex flex-col w-screen items-center relative">
        <span className="l-text-100 !text-[44px] md:!text-[100px] !font-bold md:!font-[400] text-white text-center">
          Why L2X?
        </span>
        <div className="absolute h-[428px] overflow-hidden bottom-0">
          <img className="w-[1200px] h-[600px]" src={bgRectangle} alt="bgRectangle" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 z-10 mt-20 gap-24 relative min-h-[540px]">
          <div className="flex mt-8 flex-col items-center w-full md:w-[460px] p-6 text-center">
            <img className="w-32" src={curveSvg} alt="iconLinea" />

            <span className="l-text-2xl-semibold text-white mt-6">01</span>
            <span className="l-text-4xl-semibold text-white">Concentrated Liquidity</span>
            <span className="l-text-base text-center text-white">
              Amplify your capital efficiency and trading experience with our cutting-edge approach to concentrated
              liquidity mechanism.
            </span>
          </div>

          <div className="flex items-center justify-center relative md:h-auto h-[586px] px-4 md:px-0">
            <div
              onClick={handleAddLiquidityClick}
              className="flex flex-col bg-dark-base-200 p-6 mx-4 md:w-[422px] gap-3 absolute hover:shadow-primary cursor-pointer"
            >
              <div className="flex items-center justify-between">
                <h2 className="l-text-base-medium text-center flex-1">Add Liquidity</h2>
                <button className="text-gray-600 hover:text-gray-800">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="divider m-0 h-[1px]" />
              <div className="flex flex-col gap-3 l-text-lg-medium">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img className="w-6 h-6" src={tokenA.imgUrl} alt="USDC" />
                    <img src={tokenB.imgUrl} alt="ETH" className="w-6 h-6 ml-[-4px] rounded-full" />
                    <span className="ml-2">
                      {tokenA.symbol} / {tokenB.symbol}
                    </span>
                  </div>
                  {/* <span className="l-text-sm text-dark-success">In Range</span> */}
                  <RangeBadge removed={removed} inRange={!outOfRange} />
                </div>
                <div className="flex flex-col bg-dark-base-100 p-3 l-text-sm text-dark-base-content">
                  <div className="flex items-center justify-between h-8">
                    <div className="flex items-center">
                      <img src={tokenA.imgUrl} alt="USDC" className="w-6 h-6 mr-2" />
                      <span className="mr-4">Pooled {tokenA.symbol}</span>
                    </div>
                    <input value={tokenAamount || '-'} className="w-16 px-2 py-1 bg-transparent text-right" />
                  </div>
                  <div className="flex items-center mb-2 justify-between h-8">
                    <div className="flex items-center">
                      <img src={tokenB.imgUrl} alt="ETH" className="w-6 h-6 mr-2 rounded-full" />
                      <span className="mr-4">Pooled {tokenB.symbol}</span>
                    </div>
                    <input value={tokenBamount || '-'} className="w-16 px-2 py-1 bg-transparent text-right" />
                  </div>
                  <div className="divider m-0 h-[1px]" />
                  <div className="flex items-center justify-between">
                    <span className="mr-4 mt-3">Fee Tier</span>
                    <span>{(existingPosition?.pool?.fee || 0) / 10000}%</span>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  <div className="flex-1 bg-dark-base-100 p-3">
                    <div className="text-center l-text-sm text-dark-base-content-06">Min Price</div>
                    <div className="text-center l-text-xl-medium text-dark-base-content">
                      {formatTickPrice({
                        price: priceLower,
                        atLimit: ticksAtLimit,
                        direction: Bound.LOWER,
                      })}
                    </div>
                    <div className="text-center text-dark-base-content-06">
                      {tokenA.symbol} per {tokenB.symbol}
                    </div>
                    <div className="text-center l-text-xs text-dark-base-content">
                      Your position will be 100% ETH at this price.
                    </div>
                  </div>
                  <img src={iconArrow} alt="arrow" />
                  <div className="flex-1 bg-dark-base-100 p-3">
                    <div className="text-center l-text-sm text-dark-base-content-06">Min Price</div>
                    <div className="text-center l-text-xl-medium text-dark-base-content">
                      {formatTickPrice({
                        price: priceUpper,
                        atLimit: ticksAtLimit,
                        direction: Bound.UPPER,
                      })}
                    </div>
                    <div className="text-center text-dark-base-content-06">
                      {tokenB.symbol} per {tokenA.symbol}
                    </div>
                    <div className="text-center l-text-xs text-dark-base-content">
                      Your position will be 100% ETH at this price.
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 bg-dark-base-100 p-3">
                    <div className="text-center l-text-sm text-dark-base-content-06">Current Price</div>
                    <div className="text-center l-text-xl-medium text-dark-base-content">{currentPrice || '--'}</div>
                    <div className="text-center l-text-sm text-dark-base-content-06">
                      {tokenA.symbol} per {tokenB.symbol}
                    </div>
                  </div>
                </div>
              </div>
              <button className="text-white px-4 py-2 w-full bg-gradient-to-r from-blue-400 to-purple-600 shadow-button">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="relative pt-[180px] bg-white">
        <div className="w-screen flex flex-col items-center">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full max-w-7xl">
            <div className="flex items-center justify-center">
              <div className="flex flex-col max-w-[360px] text-center items-center justify-center gap-3">
                <span className="l-text-2xl-semibold text-dark-button-content">02</span>
                <span className="l-text-4xl-semibold text-dark-button-content">Automated Liquidity Management</span>
                <span className="l-text-sm text-dark-button-content">
                  L2X supports automated liquidity management, streamlining the process and elevating your LP
                  effectiveness.
                </span>
              </div>
            </div>

            <div className="flex items-center justify-center pb-24">
              <img className="w-72" src={iconLiquidityManagement} alt="iconLiquidityManagement" />
            </div>

            <div className="flex items-center justify-center pt-24 pb-32 border-r border-t">
              <div className="flex flex-col flex-1 items-center gap-3 max-w-[360px] text-center">
                <img className="w-72" src={iconUserExperience} alt="iconUserExperience" />
                <span className="l-text-2xl-semibold text-dark-button-content">03</span>
                <span className="l-text-4xl-semibold text-dark-button-content">Enhanced User Experience</span>
                <span className="l-text-sm text-dark-button-content">
                  Immerse yourself in a seamlessly intuitive trading journey that prioritizes your needs with optimized
                  UX.
                </span>
              </div>
            </div>

            <div className="flex items-center justify-center pt-24 pb-32 border-t">
              <div className="flex flex-col flex-1 items-center gap-3 max-w-[360px] text-center">
                <img className="w-72" src={iconCommunityEmpowered} alt="iconUserExperience" />
                <span className="l-text-2xl-semibold text-dark-button-content">04</span>
                <span className="l-text-4xl-semibold text-dark-button-content">Empowered by Community</span>
                <span className="l-text-sm text-dark-button-content">
                  Join a thriving community of traders, liquidity providers, and enthusiasts who collaboratively shape
                  L2X into the premier DEX on Astar zkEVM.
                </span>
              </div>
            </div>
          </div>
          <img className="w-[480px] absolute right-[0px] bottom-[-100px]" src={bgWire} alt="wire" />
        </div>
      </div>
    </>
  )
}
