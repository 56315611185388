import { MaxButton } from 'pages/Pool/styled'
import { Text } from 'rebass'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  /* padding: 20px; */
  padding: 20px 12px;
  min-width: 460px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    min-width: 340px;
  `};
`

export const SmallMaxButton = styled(MaxButton)`
  border: 1px solid var(--dark-base-content-04, rgba(166, 173, 186, 0.4));
  background: var(--dark-base-200, #242933);
  color: var(--dark-base-content-06, rgba(166, 173, 186, 0.6));
  font-size: 14px;
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.7);

  :focus {
    border: 1px solid ${({ theme }) => theme.accent1};
    color: var(--dark-secondary-content, #fff);
    background-color: var(--dark-secondary, #d926a9);
    outline: none;
    box-shadow: none;
  }
`

export const ResponsiveHeaderText = styled(Text)`
  font-size: 40px;
  font-weight: 535;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
     font-size: 24px
  `};
`
