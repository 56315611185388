import { CurrencyAmount } from '@uniswap/sdk-core'
import { formatUnits } from 'ethers/lib/utils'
import { useToken } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionInfo } from 'state/transactions/types'

import { DarkCard } from '../../../components/Card/index'
import { InfoCell } from '../components/InfoCell'
import { useFairLaunchContext } from '../useFairLaunchContext'
import { useFairLaunchCreatePool, useTokenInfo } from '../useFairLaunchHook'
const FormItemFinish = () => {
  const { setCurrentStep } = useFairLaunchContext()
  const { getValues } = useFormContext()
  const formValues = getValues()
  const {
    totalSellingAmount,
    tokenAddress,
    softCap,
    website,
    endTime,
    startTime,
    liquidityRate,
    maxContribution,
    lockTime,
  } = formValues
  const { name, symbol, totalSupply, decimals } = useTokenInfo(tokenAddress)
  const { createPool, fairPoolFactoryAddress, isLoading, isSuccess } = useFairLaunchCreatePool(formValues)
  const addTransaction = useTransactionAdder()
  const fairlaunchToken = useToken(tokenAddress)
  const liquidityAmount = ((totalSellingAmount * liquidityRate) / 100).toFixed()
  const toApproveAmount = Number(totalSellingAmount) + Number(liquidityAmount)
  const navigate = useNavigate()

  const approveTokenAmount = fairlaunchToken
    ? CurrencyAmount.fromRawAmount(fairlaunchToken, toApproveAmount * 10 ** decimals)
    : undefined
  const [tokenApproveState, approveCallback] = useApproveCallback(approveTokenAmount, fairPoolFactoryAddress, true)

  const handleSubmit = async () => {
    const { response, info } = await createPool()
    addTransaction(response, info as TransactionInfo)
  }

  if (isSuccess) {
    navigate('/fairlaunch')
  }

  return (
    <>
      <DarkCard className="m-8">
        <div className="text-[24px] font-medium mb-2">Finish</div>
        <InfoCell title="Total token" value={formatUnits(totalSupply)} />
        <InfoCell title="Token Name" value={name} />
        <InfoCell title="Token symbol" value={symbol} />
        <InfoCell title="Token decimals" value={decimals} />
        <InfoCell title="Total selling amount" value={totalSellingAmount} />
        <InfoCell title="SoftCap" value={softCap} />
        {maxContribution ? <InfoCell title="Max Contribution" value={maxContribution || 0} /> : null}
        <InfoCell title="Liquidity" value={`${liquidityRate}%`} />
        <InfoCell title="Start time" value={new Date(startTime).toUTCString()} />
        <InfoCell title="End time" value={new Date(endTime).toUTCString()} />
        <InfoCell title="Liquidity lockup time" value={`${lockTime}minutes`} />
        <InfoCell title="Website" value={website} />
      </DarkCard>
      {tokenApproveState === ApprovalState.NOT_APPROVED || tokenApproveState === ApprovalState.PENDING ? (
        <div className="w-full flex my-6">
          <span></span>
          <button
            className="btn btn-primary rounded-sm"
            disabled={tokenApproveState === ApprovalState.PENDING}
            onClick={async () => {
              await approveCallback()
            }}
          >
            Approve Spending Token {Number(toApproveAmount)} {symbol}
            {tokenApproveState === ApprovalState.PENDING && <span className="loading loading-spinner"></span>}
          </button>
        </div>
      ) : null}
      <div className="flex gap-4 my-4">
        <button
          type="submit"
          className="btn btn-neutral text-dark-base-content-06 bg-dark-base-200 hover:bg-dark-base-300 border-dark-base-content-04 px-12"
          onClick={() => setCurrentStep(3)}
        >
          Back
        </button>
        <button
          disabled={tokenApproveState !== ApprovalState.APPROVED || isLoading}
          className="btn bg-gradient-primary border-none text-white px-12"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </>
  )
}

export default FormItemFinish
