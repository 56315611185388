import { DarkCard } from 'components/Card'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { isDevelopmentEnv } from '../../../utils/env'
import { ChainId, TOKEN_LIST } from '../constants'
import { useFairLaunchContext } from '../useFairLaunchContext'
import { useTokenInfo } from '../useFairLaunchHook'

const CurrencyOptions = () => {
  const chainIdAsEnv = isDevelopmentEnv() ? ChainId.LineaTestnet : ChainId.Linea
  const currencyOptionList = [
    {
      name: 'USDT',
      address: TOKEN_LIST[chainIdAsEnv].USDT,
    },
    {
      name: 'USDC',
      address: TOKEN_LIST[chainIdAsEnv].USDC,
    },
  ]
  const defaultCurrency = currencyOptionList[0]
  const { setValue, watch } = useFormContext()
  const currency = watch('currency', defaultCurrency)

  useEffect(() => {
    setValue('currency', defaultCurrency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {currencyOptionList.map((item, index) => {
        return (
          <button
            key={index}
            className={`btn ${currency?.address == item?.address ? 'btn-secondary' : 'btn-neutral'} shadow-button mr-4`}
            onClick={() => setValue('currency', item)}
          >
            {item.name.toUpperCase()}
          </button>
        )
      })}
      <p className="mt-2 text-[12px] text-dark-base-content-06">Users will pay with {currency?.name} for your token</p>
    </div>
  )
}

const FormItemVerifyToken = () => {
  const {
    control,
    formState: { errors },
    trigger,
    watch,
    clearErrors,
  } = useFormContext()

  const tokenAddress = watch('tokenAddress')
  const { setCurrentStep } = useFairLaunchContext()
  const onCreateToken = (e: React.FormEvent) => {
    e.preventDefault()
    console.log(e)
  }
  const { error: isTokenInfoError } = useTokenInfo(tokenAddress)

  return (
    <>
      <DarkCard>
        <div className="text-[24px] font-medium mb-2">Verify Token</div>
        <div className="form-control w-full my-4">
          {/* <div className="flex justify-between">
            <label className="label ">
              <span className="label-text-required">Token address</span>
            </label>
            <button
              className="btn btn-sm border-none text-white text-[12px] bg-gradient-primary"
              onClick={() => (document.getElementById('my_modal_1') as any).showModal()}
            >
              Create Token
            </button>
          </div> */}
          <Controller
            name="tokenAddress"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                required
                placeholder="Paste your token address or create a new token"
                className="input input-bordered w-full bg-[#2A303C]"
                onChange={(e) => {
                  clearErrors('tokenAddress')
                  field.onChange(e.target.value)
                }}
              />
            )}
          />
          {errors.tokenAddress && <p className="text-[12px] text-secondary">This field is Required</p>}
          {isTokenInfoError ? <p className="text-[12px] text-secondary">Invalid token address</p> : null}
        </div>
        <div className="form-control w-full my-4">
          <label className="label">
            <span className="label-text-required">Currency</span>
          </label>
          <div className="flex gap-4">
            <CurrencyOptions />
          </div>
        </div>
        {/* <div className="form-control w-full my-4">
          <label className="label">
            <span className="label-text-required">Fee Options</span>
          </label>
          <Options
            options={[
              {
                label: (
                  <span>
                    5% {currency} raised only <span className="text-secondary">(Recommended)</span>
                  </span>
                ),
                value: '5%',
              },
              {
                label: <span>other</span>,
                value: 'other',
              },
            ]}
            onChange={(optionKey) => {
              setValue('poolFee', optionKey)
            }}
          />
        </div> */}

        {/* <div className="form-control w-full my-4">
          <label className="label">
            <span className="label-text-required">Disable Affiliate</span>
          </label>
          <Options
            options={[
              {
                label: <span>Disable Affiliate </span>,
                value: false,
              },
              {
                label: <span>Enable Affiliate</span>,
                value: true,
              },
            ]}
            onChange={(optionKey) => {
              setValue('affiliate', optionKey)
            }}
          />
        </div> */}
      </DarkCard>
      <div className="flex gap-4 my-4">
        <button
          className="btn btn-gradient border-none text-white px-6"
          onClick={() => {
            trigger().then((isValid) => {
              if (isValid && !isTokenInfoError) {
                setCurrentStep(2)
              }
            })
          }}
        >
          Next
        </button>
      </div>
      <dialog id="my_modal_1" className="modal">
        <div className="modal-box">
          <form method="dialog">
            <div className="form-control w-full my-4">
              <label className="label ">
                <span className="label-text-required">Token Type</span>
              </label>
              <select className="select select-bordered w-full">
                <option selected>Standard Token</option>
                {/* <option disabled>Han Solo</option>
                <option>Greedo</option> */}
              </select>
              {errors.tokenAddress && <p className="text-[12px] text-secondary">This field is Required</p>}
            </div>
            <div className="form-control w-full my-4">
              <div className="flex justify-between">
                <label className="label ">
                  <span className="label-text-required">Name</span>
                </label>
              </div>
              <input type="text" placeholder="" className="input input-bordered w-full bg-[#2A303C]" />
              {errors.tokenAddress && <p className="text-[12px] text-secondary">This field is Required</p>}
            </div>
            <div className="form-control w-full my-4">
              <div className="flex justify-between">
                <label className="label ">
                  <span className="label-text-required">Symbol</span>
                </label>
              </div>
              <input type="text" placeholder="" className="input input-bordered w-full bg-[#2A303C]" />
              {errors.tokenAddress && <p className="text-[12px] text-secondary">This field is Required</p>}
            </div>

            <div className="form-control w-full my-4">
              <div className="flex justify-between">
                <label className="label ">
                  <span className="label-text-required">Decimals</span>
                </label>
              </div>
              <input type="text" placeholder="" className="input input-bordered w-full bg-[#2A303C]" />
              {errors.tokenAddress && <p className="text-[12px] text-secondary">This field is Required</p>}
            </div>
            <div className="form-control w-full my-4">
              <div className="flex justify-between">
                <label className="label ">
                  <span className="label-text-required">Total supply</span>
                </label>
              </div>
              <input type="text" placeholder="" className="input input-bordered w-full bg-[#2A303C]" />
              {errors.tokenAddress && <p className="text-[12px] text-secondary">This field is Required</p>}
            </div>
            <div className="">
              <button className="btn btn-gradient mr-2" onClick={onCreateToken}>
                Create Token
              </button>
              <button className="btn btn-neutral">Close</button>
            </div>
          </form>
        </div>
      </dialog>
    </>
  )
}

export default FormItemVerifyToken
