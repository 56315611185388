import { Controller, useFormContext } from 'react-hook-form'

import { DarkCard } from '../../../components/Card/index'
import { useFairLaunchContext } from '../useFairLaunchContext'
const FormItemAdditionalInfo = () => {
  const { setCurrentStep } = useFairLaunchContext()
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <DarkCard className="m-8">
        <div className="text-[24px] font-medium mb-2">Add Additional Info</div>
        <div className="flex gap-4">
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="label-text-required">Logo URL</span>
            </label>
            <Controller
              name="logoUrl"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
            {errors.logoUrl && <p className="text-[12px] text-secondary">This field is Required</p>}
          </div>
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="label-text-required">Website</span>
            </label>
            <Controller
              name="website"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
            {errors.website && <p className="text-[12px] text-secondary">This field is Required</p>}
          </div>
        </div>
        <div className="flex gap-4">
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Facebook</span>
            </label>
            <Controller
              name="facebook"
              control={control}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
          </div>
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Twitter</span>
            </label>
            <Controller
              name="twitter"
              control={control}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Github</span>
            </label>
            <Controller
              name="github"
              control={control}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
          </div>

          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Telegram</span>
            </label>
            <Controller
              name="telegram"
              control={control}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Instagram</span>
            </label>
            <Controller
              name="instagram"
              control={control}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
          </div>

          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Discord</span>
            </label>
            <Controller
              name="discord"
              control={control}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Reddit</span>
            </label>
            <Controller
              name="reddit"
              control={control}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Youtube Video</span>
            </label>
            <Controller
              name="youtube"
              control={control}
              render={({ field }) => (
                <input {...field} type="text" placeholder="Ex: https://..." className="input input-bordered w-full " />
              )}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="form-control w-full my-2">
            <label className="label label-required">
              <span className="">Description</span>
            </label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <textarea {...field} placeholder="Ex: https://..." className="textarea textarea-bordered w-full " />
              )}
            />
          </div>
        </div>
      </DarkCard>
      <div className="flex gap-4 my-4">
        <button
          className="btn btn-neutral text-dark-base-content-06 bg-dark-base-200 hover:bg-dark-base-300 border-dark-base-content-04 px-12"
          onClick={() => {
            setCurrentStep(2)
          }}
        >
          Back
        </button>
        <button
          className="btn bg-gradient-primary border-none text-white px-12"
          onClick={() =>
            trigger().then((isValid) => {
              if (isValid) {
                setCurrentStep(4)
              }
            })
          }
        >
          Next
        </button>
      </div>
    </>
  )
}

export default FormItemAdditionalInfo
