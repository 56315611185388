import { createReducer } from '@reduxjs/toolkit'

import { selectPool } from './actions'

export type FairlaunchPoolState = {
  [x: string]: any
}

export interface FairlaunchState {
  currentPool: FairlaunchPoolState
}

const initialState: FairlaunchState = {
  currentPool: {},
}

export default createReducer<FairlaunchState>(initialState, (builder) =>
  builder.addCase(selectPool, (state, { payload: pool }) => {
    console.log({ pool })
    const {
      tokenInfo,
      poolInfo,
      payment,
      saleInfo,
      liquidity,
      poolDetail,
      website,
      logoUrl,
      github,
      facebook,
      twitter,
      telegram,
      discord,
      reddit,
      youtube,
      description,
      isLive,
      isNotStart,
      isEnd,
    } = pool

    return {
      ...state,
      currentPool: {
        tokenInfo: {
          name: tokenInfo.name,
          symbol: tokenInfo.symbol,
          token: tokenInfo.token,
          decimals: tokenInfo.decimals.toString(),
          totalsupply: tokenInfo.totalsupply.toString(),
        },
        poolInfo: {
          starttime: poolInfo.starttime.toString(),
          endtime: poolInfo.endtime.toString(),
          hardCap: poolInfo.hardCap.toString(),
          softCap: poolInfo.softCap.toString(),
          tokensForSale: poolInfo.tokensForSale.toString(),
          isMaxBuyLimit: poolInfo.isMaxBuyLimit,
          isWhitelist: poolInfo.isWhitelist,
          state: poolInfo.state,
          poolAddress: poolInfo.poolAddress,
          creator: poolInfo.creator,
        },
        payment: {
          fee: payment.fee.toString(),
          token: payment.token,
        },
        saleInfo: {
          totalClaimed: saleInfo.totalClaimed.toString(),
          totalRaised: saleInfo.totalRaised.toString(),
          totalRefunded: saleInfo.totalRefunded.toString(),
        },
        liquidity: {
          lockTime: liquidity.lockTime.toString(),
          unlockTime: liquidity.unlockTime.toString(),
          tokensForLiquidity: liquidity.tokensForLiquidity.toString(),
        },
        poolDetail,
        website,
        logoUrl,
        github,
        facebook,
        twitter,
        telegram,
        discord,
        reddit,
        youtube,
        description,
        isLive,
        isNotStart,
        isEnd,
      },
    }
  })
)
