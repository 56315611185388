import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

// const MIN_NATIVE_CURRENCY_FOR_GAS: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const MIN_NATIVE_CURRENCY_FOR_GAS: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(15)) // .001 ETH
/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount<Currency>): {
  withGasGap: boolean
  currencyAmount?: CurrencyAmount<Currency>
} {
  if (!currencyAmount) return { withGasGap: false, currencyAmount: undefined }
  if (currencyAmount.currency.isNative) {
    if (JSBI.greaterThan(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS)) {
      return {
        withGasGap: true,
        currencyAmount: CurrencyAmount.fromRawAmount(
          currencyAmount.currency,
          JSBI.subtract(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS)
        ),
      }
    } else {
      return {
        withGasGap: false,
        currencyAmount: CurrencyAmount.fromRawAmount(currencyAmount.currency, JSBI.BigInt(0)),
      }
    }
  }
  return { withGasGap: false, currencyAmount }
}
