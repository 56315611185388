import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import SettingsTab from 'components/Settings'
import { InterfaceTrade } from 'state/routing/types'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import { RowBetween, RowFixed } from '../Row'

const StyledSwapHeader = styled(RowBetween)`
  padding-bottom: 14px;
  margin-bottom: 14px;
  color: ${({ theme }) => theme.neutral2};
  border-bottom: 1px solid #313640;
`

const HeaderButtonContainer = styled(RowFixed)`
  /* padding: 0 12px; */
  gap: 16px;
`

export default function SwapHeader({
  autoSlippage,
  chainId,
  trade,
}: {
  autoSlippage: Percent
  chainId?: number
  trade?: InterfaceTrade
}) {
  // const fiatOnRampButtonEnabled = useFiatOnRampButtonEnabled()

  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <ThemedText.SubHeader>
          <Trans>Swap</Trans>
        </ThemedText.SubHeader>
        {/* {fiatOnRampButtonEnabled && <SwapBuyFiatButton />} */}
      </HeaderButtonContainer>
      <RowFixed>
        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} trade={trade} />
      </RowFixed>
    </StyledSwapHeader>
  )
}
