const setFavicon = (): void => {
  const faviconEl = document.getElementById('favicon') as HTMLLinkElement
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  if (isDarkMode) {
    faviconEl.href = `${process.env.PUBLIC_URL}/favicon_black.ico`
  } else {
    faviconEl.href = `${process.env.PUBLIC_URL}/favicon_white.ico`
  }
}

export default setFavicon
