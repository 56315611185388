import { BigNumber } from '@ethersproject/bignumber'
import { DarkCard } from 'components/Card'
import { Options } from 'components/Options'
import { formatUnits } from 'ethers/lib/utils'
import { Controller, useFormContext } from 'react-hook-form'

import { useFairLaunchContext } from '../useFairLaunchContext'
import { useTokenInfo } from '../useFairLaunchHook'

const FormItemFairLaunchInfo = () => {
  const { setCurrentStep } = useFairLaunchContext()
  const {
    setValue,
    getValues,
    control,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext()

  const isMaxContribution = watch('isMaxContribution', false)
  const isEnableBuyback = watch('isEnableBuyback', false)
  const liquidityRate = watch('liquidityRate', 0)
  const startTime = watch('startTime', new Date().valueOf())
  const totalSellingAmount = watch('totalSellingAmount', 0)
  const currency = getValues('currency')
  const tokenAddress = getValues('tokenAddress')
  const { symbol, totalSupply: bigNumTotalSupply, decimals } = useTokenInfo(tokenAddress)

  return (
    <>
      <DarkCard className="m-8">
        <div className="text-[24px] font-medium">DeFi Fairlaunch Info</div>
        <div className="form-control w-full my-4">
          <label className="label label-required">
            <span className="label-text-required">Total selling amount</span>
          </label>
          <Controller
            name="totalSellingAmount"
            control={control}
            rules={{
              required: true,
              validate: {
                lessThanTotalSupply: (value) =>
                  BigNumber.from(value).mul(BigNumber.from(10).pow(decimals)).lte(bigNumTotalSupply) ||
                  `should be less than Total Supply (${formatUnits(bigNumTotalSupply.toString(), decimals)})`,
              },
            }}
            render={({ field }) => (
              <input {...field} type="text" placeholder="100" className="input w-full bg-dark-base-100" />
            )}
          />
          {errors.totalSellingAmount && (
            <p className="text-[12px] text-secondary">
              {(errors.totalSellingAmount.message as string) || 'This field is Required'}
            </p>
          )}
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="">Whitelist</span>
          </label>
          <Options
            options={[
              { label: 'Disable', value: false },
              { label: 'Enable', value: true },
            ]}
            onChange={(option) => setValue('isWhitelist', option)}
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text-required">Softcap({currency.name})</span>
          </label>
          <Controller
            name="softCap"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                placeholder="Ex: 10"
                className="input input-bordered w-full bg-dark-base-100"
              />
            )}
          />
          {errors.softCap && <p className="text-[12px] text-secondary">This field is Required</p>}

          <div className="form-control w-full">
            <div className="flex items-center mb-1">
              <Controller
                name="isMaxContribution"
                control={control}
                render={({ field }) => <input {...field} type="checkbox" className="checkbox checkbox-secondary" />}
              />
              <span className="ml-2">Setting max contribution?</span>
            </div>
            {isMaxContribution ? (
              <>
                <label className="label">
                  <span className="label-text-required">Max Contribution(ETH)</span>
                </label>

                <Controller
                  name="maxContribution"
                  defaultValue={0}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="number"
                      placeholder="Ex:10"
                      className="input input-bordered w-full bg-dark-base-100"
                    />
                  )}
                />
              </>
            ) : null}
          </div>
        </div>

        {/* <div className="form-control w-full">
          <label className="label">
            <span className="label-text-required">Router</span>
          </label>
          <Controller
            name="router"
            control={control}
            defaultValue="router1"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <select {...field} className="select select-bordered w-full">
                <option value="router1">Router 1</option>
                <option value="router2">Router 2</option>
              </select>
            )}
          />
          {errors.tokenAddress && <p className="text-[12px] text-secondary">This field is Required</p>}
        </div> */}

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text-required">liquidity (%)</span>
          </label>

          <Controller
            name="liquidityRate"
            control={control}
            rules={{
              required: true,
              min: 51,
            }}
            render={({ field }) => (
              <input
                {...field}
                type="number"
                placeholder="0"
                className="input input-bordered w-full bg-dark-base-100"
              />
            )}
          />
          {errors.liquidityRate?.type === 'required' && (
            <p className="text-[12px] text-secondary">This field is Required</p>
          )}
          {errors.liquidityRate?.type === 'min' && (
            <p className="text-[12px] text-secondary">Liquidity rate must be more than 51</p>
          )}
        </div>

        <div className="form-control w-full">
          <div className="flex items-center mb-1">
            <Controller
              name="isEnableBuyback"
              control={control}
              render={({ field }) => <input {...field} type="checkbox" className="checkbox checkbox-secondary" />}
            />
            <span className="ml-2 text-dark-base-content-06">Enable Buyback?</span>
          </div>

          {isEnableBuyback ? (
            <>
              <label className="label">
                <span className="">Buyback Percent (%)</span>
              </label>
              <Controller
                name="buyback"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="number"
                    placeholder="0"
                    className="input input-bordered w-full bg-dark-base-100"
                  />
                )}
              />
            </>
          ) : null}
          <div>
            <p className="text-[14px] text-dark-base-content p-with-dot">
              Enter the percentage of raised funds that should be allocated to Liquidity on (Min 51%, Max 100%)
            </p>
            <p className="text-[14px] text-dark-base-content p-with-dot">
              If I spend 1 BUSD on how many tokens will I receive? Usually this amount is lower than presale rate to
              allow for a higher listing price on
            </p>
          </div>
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="">Select start time & end time (UTC)</span>
          </label>
          <div className="flex gap-8 w-full justify-between">
            <div className="relative w-full">
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    lessThanEndTime: (value, formValue) =>
                      value.valueOf() < formValue.endTime.valueOf() || 'should be less than end time',
                    greatThanNow: (value) =>
                      new Date(value).valueOf() > new Date().valueOf() || 'should be greater than now',
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    onFocus={(e) => {
                      e.target.type = 'datetime-local'
                      e.target?.showPicker?.()
                    }}
                    placeholder="Select date"
                    className="input input-bordered w-full bg-dark-base-100"
                  />
                )}
              />
              {errors.startTime && (
                <p className="text-[12px] text-secondary">
                  {(errors.startTime.message as string) || 'This field is Required'}
                </p>
              )}
            </div>
            <div className="relative w-full">
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    greaterThanEndTime: (value) =>
                      value.valueOf() > startTime.valueOf() || 'should be greater than start time',
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    onFocus={(e) => {
                      e.target.type = 'datetime-local'
                      e.target?.showPicker?.()
                    }}
                    placeholder="Select date"
                    className="input input-bordered w-full bg-dark-base-100"
                  />
                )}
              />
              {errors.endTime?.type === 'required' && (
                <p className="text-[12px] text-secondary">
                  {(errors?.endTime?.message as string) || 'This field is Required'}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text-required">Liquidity lockup (minutes)</span>
          </label>

          <Controller
            name="lockTime"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <input
                {...field}
                type="number"
                placeholder="0"
                className="input input-bordered w-full bg-dark-base-100"
              />
            )}
          />
          {errors.lockTime && <p className="text-[12px] text-secondary">This field is Required</p>}
        </div>
      </DarkCard>
      {totalSellingAmount && liquidityRate ? (
        <p className="my-4 text-success">
          Need {Number(totalSellingAmount) + Number(totalSellingAmount * liquidityRate) / 100} {symbol} to create
          launchpad.
        </p>
      ) : null}
      <div className="flex gap-4 my-4">
        <button
          className="btn btn-neutral text-dark-base-content-06 bg-dark-base-200 hover:bg-dark-base-300 border-dark-base-content-04 px-12"
          onClick={() => setCurrentStep(1)}
        >
          Back
        </button>
        <button
          className="btn bg-gradient-primary border-none text-white px-12"
          onClick={() =>
            trigger().then((isValid) => {
              if (isValid) {
                setCurrentStep(3)
              }
            })
          }
        >
          Next
        </button>
      </div>
    </>
  )
}

export default FormItemFairLaunchInfo
