import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { Token } from '@uniswap/sdk-core'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { DarkCard } from 'components/Card'
import { formatUnits } from 'ethers/lib/utils'
import { useToken } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import useInterval from 'lib/hooks/useInterval'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'

import { InfoCell } from '../components/InfoCell'
import { PoolStatus } from '../components/PoolStatus'
import { ChainId } from '../constants'
import { useFairPoolCancel, useFairPoolContribute, useFairPoolSetEndTime } from '../useFairLaunchHook'
import { findTokenNameByAddress, getCountdown } from '../utils'
import { MetricsChart } from './MetricsCharts'
const SEC_IN_MIN = 60

const Avatar = ({ src }: { src: string }) => {
  return (
    <div className="avatar">
      <div className="w-[80px] bg-slate-500 rounded-full">
        <img src={src} />
      </div>
    </div>
  )
}

const CountDown = ({ countTime }: { countTime: number }) => {
  const [countDown, setCountDown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })

  const handleGetCountdown = useCallback(() => {
    const { days, hours, minutes, seconds } = getCountdown(countTime)
    setCountDown({ days, hours, minutes, seconds })
  }, [countTime])
  useInterval(handleGetCountdown, 1000)

  return (
    <span className="countdown font-[600] text-[30px] text-center">
      <span style={{ '--value': countDown.days } as React.CSSProperties}></span>:
      <span style={{ '--value': countDown.hours } as React.CSSProperties}></span>:
      <span style={{ '--value': countDown.minutes } as React.CSSProperties}></span>:
      <span style={{ '--value': countDown.seconds } as React.CSSProperties}></span>
    </span>
  )
}

const SetEndTimeBtn = ({ poolAddress }: { poolAddress: string }) => {
  const [endTime, setEndTime] = useState<Date | undefined>()
  const { setEndTime: setFairPoolEndTime, isSuccess, isLoading } = useFairPoolSetEndTime(poolAddress)

  const handleConfirmSetEndTime = (e: React.MouseEvent) => {
    e.preventDefault()
    if (endTime === undefined) return
    setFairPoolEndTime(+(endTime.getTime() / 1000).toFixed())
  }
  return (
    <>
      <button
        className="btn w-full mt-2"
        onClick={() => {
          const modal = document.getElementById('endtime_modal') as HTMLDialogElement
          if (modal) {
            modal.showModal()
          }
        }}
      >
        Set EndTime
      </button>
      <dialog id="endtime_modal" className="modal">
        <div className="modal-box overflow-visible">
          <div className="flex items-center">
            <h3 className="font-bold text-lg">Set EndTime</h3>
            <div className="ml-2 flex-1">
              <input
                onFocus={(e) => {
                  e.target.type = 'datetime-local'
                  e.target?.showPicker?.()
                }}
                className="w-full bg-dark-base-200 px-2 h-[3rem]"
              />
              {/* <DateTimePicker
                className="w-full bg-dark-base-200 px-2 h-[3rem]"
                value={endTime}
                onChange={(e) => {
                  if (e) {
                    setEndTime(new Date(e))
                  }
                }}
              /> */}
            </div>
          </div>
          <div className="modal-action justify-normal">
            <form method="dialog">
              <button className="btn">Close</button>
              <button disabled={isLoading} className="btn btn-gradient ml-2" onClick={handleConfirmSetEndTime}>
                Confirm
                {isLoading ? <span className="loading loading-spinner"></span> : ''}
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  )
}

const Contribute = ({ paymentTokenAddress, poolAddress }: { paymentTokenAddress: string; poolAddress: string }) => {
  const { chainId, account } = useWeb3React()
  const [amountOfBuy, setAmountOfBuy] = useState('0.0')
  const paymentToken = paymentTokenAddress ? new Token(ChainId.LineaTestnet, paymentTokenAddress, 18) : undefined
  const tokenBalance = useTokenBalance(account ?? undefined, paymentToken)
  const paymentTokenName =
    chainId && paymentTokenAddress ? findTokenNameByAddress({ address: paymentTokenAddress, chainId }) : ''
  const paymentTokenCurrency = useToken(paymentTokenAddress)
  const approveTokenAmount = paymentTokenCurrency
    ? CurrencyAmount.fromRawAmount(paymentTokenCurrency, +amountOfBuy * 10 ** 18)
    : undefined
  const [tokenApproveState, approveCallback] = useApproveCallback(approveTokenAmount, poolAddress, true)
  const addTransaction = useTransactionAdder()
  const { contribute, isLoading, isSuccess } = useFairPoolContribute(poolAddress)

  const onMaxBtnClick = () => {
    setAmountOfBuy(tokenBalance?.toExact() ?? '0.0')
  }

  const contributeHandler = async () => {
    if (Number(amountOfBuy) <= 0) return
    const { response, info } = await contribute(Number(amountOfBuy))
    addTransaction(response, info)
  }

  return (
    <div>
      <div className="mt-4">
        Amount (max: {tokenBalance?.toFixed(2)} {paymentTokenName})
      </div>

      <div className="relative">
        <input
          type="text"
          placeholder="0.0"
          value={amountOfBuy}
          className="input w-full max-w-xs"
          onChange={(e) => setAmountOfBuy(e.currentTarget.value)}
        />
        <button
          className="btn btn-sm btn-secondary btn-outline border-none absolute right-2 top-4"
          onClick={onMaxBtnClick}
        >
          MAX
        </button>
      </div>
      {tokenApproveState === ApprovalState.NOT_APPROVED || tokenApproveState === ApprovalState.PENDING ? (
        <button
          className="btn btn-gradient w-full mt-2"
          disabled={isLoading || +amountOfBuy <= 0 || isNaN(+amountOfBuy)}
          onClick={async () => await approveCallback()}
        >
          Approve {paymentTokenName}
        </button>
      ) : (
        <button
          className="btn btn-gradient w-full mt-2"
          disabled={isLoading || +amountOfBuy <= 0 || isNaN(+amountOfBuy)}
          onClick={contributeHandler}
        >
          Buy with {paymentTokenName}
        </button>
      )}
    </div>
  )
}

export const LaunchProjectDetail = () => {
  const { chainId, account } = useWeb3React()
  const navigate = useNavigate()
  const pool = useAppSelector((state) => state.fairlaunch.currentPool)
  const { tokenInfo, poolInfo, payment, saleInfo, liquidity, logoUrl, description, isLive, isNotStart, isEnd } =
    pool || {}

  if (!tokenInfo) {
    navigate('/fairlaunch')
  }

  const { token: paymentTokenAddress } = payment || {}
  const paymentTokenName =
    chainId && paymentTokenAddress ? findTokenNameByAddress({ address: paymentTokenAddress, chainId }) : ''
  const { totalClaimed, totalRaised } = saleInfo || {}
  const { name: tokenName, symbol, token, decimals, totalsupply } = tokenInfo || {}
  const { tokensForLiquidity, unlockTime, lockTime } = liquidity || {}
  const {
    poolAddress,
    creator,
    starttime,
    endtime,
    hardCap,
    softCap,
    tokensForSale,
    state: poolState,
    isWhitelist,
  } = poolInfo || {}

  const lockupTime = ((Number(unlockTime) - Number(lockTime)) / SEC_IN_MIN).toFixed()
  const endTime = Number(endtime) * 1000

  const progressRate = totalRaised / softCap
  const startTime = Number(starttime) * 1000
  const { cancelPool } = useFairPoolCancel(poolAddress)
  const addTransaction = useTransactionAdder()

  const cancelPoolHandler = async () => {
    const { response, info } = await cancelPool()
    addTransaction(response, info)
  }

  return (
    <div className="w-full max-w-[1200px] px-4 mb-8">
      <div className="my-6 cursor-pointer" onClick={() => navigate(-1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
          <path d="M3.39948 12H21.3995" stroke="#A6ADBA" strokeWidth="1.5" />
          <path d="M9.3994 18L3.39941 12L9.3994 6" stroke="#A6ADBA" strokeWidth="1.5" />
        </svg>
        <span className="ml-2">Launchpad List</span>
      </div>
      <div className="flex gap-4">
        <div className="flex-[0_0_75%]">
          <DarkCard>
            <div className="flex justify-between">
              <div className="flex">
                <Avatar src={logoUrl} />
                <div className="text-[20px] font-[500] text-dark-base-content ml-4">{tokenName} Token Launch</div>
              </div>
              <div>
                <PoolStatus startTime={startTime} endTime={endTime} />
              </div>
            </div>
            {description ? <p className="text-[14px] leading-[20px] mt-4">{description}</p> : null}

            <div className="mt-8">
              <InfoCell title="Presale Address" value="Ox0097c18Fe544D72d69980A28dOC52A16f52a6Ca7" />
              <InfoCell title="Token Name" value={tokenName} />
              <InfoCell title="Token Symbol" value={symbol} />
              <InfoCell title="Token Decimals" value={decimals} />
              <InfoCell title="Token Address" value={token} />
              <InfoCell title="Token Supply" value={totalsupply ? formatUnits(totalsupply, decimals) : '--'} />
              <InfoCell title="Tokens For Liquidity" value={tokensForLiquidity} />
              <InfoCell title="Initial Market Cap(estimate)" value="--" />
              <InfoCell title="Soft Cap" value={softCap ? `${formatUnits(softCap)} ${paymentTokenName}` : '--'} />
              <InfoCell
                title="Hard Cap"
                value={hardCap ? (BigNumber.from(hardCap).eq(MaxUint256) ? 'MAX' : hardCap) : '--'}
              />
              <InfoCell title="Presale Start Time" value={new Date(starttime * 1000).toString()} />
              <InfoCell title="Presale End Time" value={new Date(endtime * 1000).toString()} />
              <InfoCell title="Listing On" value="L2X" />
              <InfoCell
                title="Liquidity Percent"
                value={
                  tokensForLiquidity
                    ? BigNumber.from(tokensForLiquidity).mul(1e2).div(BigNumber.from(tokensForSale)).toString() + '%'
                    : '--'
                }
              />
              <InfoCell title="Liquidity Lockup Time" value={lockupTime} />
            </div>
          </DarkCard>
          <DarkCard className="!mt-4">
            <div>Token Metrics</div>
            <div className="divider"></div>
            <MetricsChart
              presale={tokensForSale ? Number(BigNumber.from(tokensForSale).toBigInt()) : 0}
              liquidity={tokensForLiquidity ? Number(BigNumber.from(tokensForLiquidity).toBigInt()) : 0}
            />
          </DarkCard>
        </div>
        <div className="flex-[0_0_30%] h-full">
          <DarkCard className=" h-full">
            <div className="text-center font-[500] text-[14px] mb-2">
              {isLive ? 'Sale Ends In' : isNotStart ? 'Sale Start in' : isEnd ? 'Sale Ended' : '--'}
            </div>
            <div className="text-center">
              {isEnd ? (
                <span className="font-[600] text-[30px] text-center">
                  <span className="mx-1">--</span>:<span className="mx-1">--</span>:<span className="mx-1">--</span>:
                  <span className="mx-1">--</span>
                </span>
              ) : (
                <CountDown countTime={isNotStart ? startTime : endTime} />
              )}
            </div>
            <progress
              className="progress progress-secondary bg-dark-base-100"
              value={progressRate * 100}
              max="100"
            ></progress>
            <div className="flex justify-between">
              <div className="text-dark-base-content-06 text-[12px]">
                <div>
                  {totalRaised ? formatUnits(totalRaised) : '--'} {paymentTokenName}
                </div>
              </div>
              <div className="text-dark-base-content-06 text-[12px]">
                {softCap ? formatUnits(softCap) : '--'} {paymentTokenName}
              </div>
            </div>

            <Contribute paymentTokenAddress={paymentTokenAddress} poolAddress={poolAddress} />
          </DarkCard>
          <DarkCard className="!mt-4">
            <div>
              <ul className="steps steps-vertical w-full">
                <li className={`step ${isNotStart ? '' : 'step-secondary'}`}>
                  <div className="relative">
                    <div>Waiting for pool start</div>
                    <div className="absolute top-[20px] text-[12px] text-dark-base-content-06">No one can purchase</div>
                  </div>
                </li>
                <li className={`step ${isLive || isEnd ? 'step-secondary' : ''}`}>
                  <div className="relative w-full text-left	">
                    <div className="w-full">Pool Start</div>
                    <div className="w-full absolute top-[20px] text-[12px] text-dark-base-content-06">
                      Pool starts at {new Date(startTime).toString()}
                    </div>
                  </div>
                </li>
                <li className={`step ${isEnd ? 'step-secondary' : ''} !min-h-[5rem]`}>
                  <div className="relative w-full text-left	">
                    <div>Pool Ended</div>
                    <div className="absolute top-[20px] text-[12px] text-dark-base-content-06">
                      Pool ends at {new Date(endTime).toString()}
                    </div>
                  </div>
                </li>
              </ul>
              <div className="mt-6">
                <InfoCell title="Status" value={poolState === 0 ? 'inprogress' : ''} />
                <InfoCell title="Sale Type" value={isWhitelist ? 'WiteList' : 'Public'} />
                <InfoCell title="Current Rate" value="N/A" />
                <InfoCell title="You purchased" value="--" />
              </div>
            </div>
          </DarkCard>
          {creator === account ? (
            <DarkCard className="!mt-4">
              <div>Pool Actions</div>
              <div className="divider"></div>
              <button className="btn w-full mt-2" onClick={cancelPoolHandler}>
                Cancel Pool
              </button>
              <SetEndTimeBtn poolAddress={poolAddress} />
            </DarkCard>
          ) : null}
        </div>
      </div>
    </div>
  )
}
