import { useWeb3React } from '@web3-react/core'
import { WrongNetworkCard } from 'components/WrongNetworkCard'
import { isSupportedChain } from 'constants/chains'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FairlaunchPoolState } from 'state/fairlaunch/reducer'

import { ProjectCard } from './components/ProjectCard'
import { LaunchProjectDetail } from './LaunchpadList/LaunchProjectDetail'
import { SaleCell } from './LaunchpadList/SaleCell'
import { useFairyPoolReader } from './useFairLaunchHook'
const Launchpad = () => {
  const navigate = useNavigate()
  const { launchID } = useParams()
  const { poolList } = useFairyPoolReader()
  const { chainId } = useWeb3React()
  const [currentTab, setCurrentTab] = useState(0)

  if (!isSupportedChain(chainId)) {
    return <WrongNetworkCard title="Token Sale Launchpad" />
  }

  if (launchID) {
    return <LaunchProjectDetail />
  }

  return (
    <div className="min-w-[1200px]">
      <div className="flex justify-between items-center my-8">
        <div>
          <h1 className="text-[32px] text-white">Token Sale Launchpad</h1>
          <p>Gain early access to public and special token sales at a lower price before they hit the market</p>
        </div>
        <button
          onClick={() => {
            navigate('/fairlaunch/create')
          }}
          className="btn btn-gradient"
        >
          + CREATE LAUNCHPAD
        </button>
      </div>
      <div className="tabs my-8">
        <a
          className={`tab tab-bordered ${currentTab === 0 ? 'tab-active' : ''} text-white text-[24px]`}
          onClick={() => {
            setCurrentTab(0)
          }}
        >
          Live projects
        </a>
        <a
          className={`tab tab-bordered text-white text-[24px] ${currentTab === 1 ? 'tab-active' : ''}`}
          onClick={() => setCurrentTab(1)}
        >
          My projects
        </a>
      </div>
      {currentTab === 0 ? (
        <>
          <div className="flex gap-8">
            {poolList?.all.notStartList?.map((pool: FairlaunchPoolState, index: number) => (
              <ProjectCard key={index} pool={pool} />
            ))}
            {poolList?.all.onGoingList?.map((pool: FairlaunchPoolState, index: number) => (
              <ProjectCard key={index} pool={pool} />
            ))}
          </div>
          <div className="my-8">
            {poolList?.all.endedList?.map((pool: FairlaunchPoolState, index: number) => (
              <SaleCell key={index} pool={pool} />
            ))}
          </div>
        </>
      ) : null}

      {currentTab === 1 ? (
        <>
          <div className="flex gap-8">
            {poolList?.my.notStartList?.map((pool: FairlaunchPoolState, index: number) => (
              <ProjectCard key={index} pool={pool} />
            ))}
            {poolList?.my.onGoingList?.map((pool: FairlaunchPoolState, index: number) => (
              <ProjectCard key={index} pool={pool} />
            ))}
          </div>
          <div className="my-8">
            {poolList?.my.endedList?.map((pool: FairlaunchPoolState, index: number) => (
              <SaleCell key={index} pool={pool} />
            ))}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Launchpad
